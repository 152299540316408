import { FETCH_ALL_CUSTOMERS, FETCH_CUSTOMER_CONTACTS } from 'actions/customerActions';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_ALL_CUSTOMERS:
            return action.payload;
        case FETCH_CUSTOMER_CONTACTS:
            return {
                    ...state,
                    ...action.payload
                };
        default:
            return state;
    }
}