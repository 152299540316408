import { map, omit, size, find, mapValues, mapKeys } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  LinearProgress,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { DeleteForever, Create, Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone'
import moment from 'moment';
import clsx from 'clsx';
import { object, string, mixed, array, number } from 'yup';

import * as itemActions from 'actions/itemActions.js';
import { useConfirm } from 'components/confirm';
import LinkHydro from 'components/linkHydro';


const useStyles = makeStyles(theme => ({
  report: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  accept: {
    backgroundColor: green[100],
  },
  reject: {
    backgroundColor: red[100]
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  add: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  sub: {
    marginTop: theme.spacing(2)
  }
}));


const Hydro = props => {
  const {
    open,
    onClose,
    edit,
    items,
    jobId,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [progress, setProgess] = React.useState('');
  const [reinit, setReinit] = React.useState(false);
  const initialValues = edit || {
        chart: '',
        date: '',
        tester: '',
        targetTp: 0,
        targetWp: 0,
        items: {},
        reports: [],
        status: 'Complete',
        jobId,
        includeOnReport: {}
      }
  return (
  <Formik
      enableReinitialize={Boolean(edit) || reinit}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        const includeOnReport =  mapValues(values.items, () => true)
        const updatedValues = { ...values, includeOnReport }
        return edit ?
        dispatch(itemActions.editHydroReport(edit.id, updatedValues, () => {
          enqueueSnackbar('Report updated', { variant: 'success'});
          actions.resetForm();
          onClose();
        }))
        :
        dispatch(itemActions.createHydroReport(updatedValues, () => {
          enqueueSnackbar('Report added', { variant: 'success'});
          actions.resetForm();
          onClose();
        }));
      }}
      validationSchema={
        object().shape({
          chart: string().required(),
          date: string().required(),
          tester: string().required(),
          targetWp: number().moreThan(1).required(),
          items: mixed().test({
            name: 'items',
            test: value => size(value) !== 0,
            message: 'Please select at least one item'
          }),
          reports: array().min(1).required()
        })
      }
    >
      {(formikProps) => {
      const {
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        resetForm
      } = formikProps;
      const handleClose = () => {
        setReinit(true);
        resetForm(initialValues);
        if (!edit) {
          map(values.reports, ({path}) => {
          dispatch(itemActions.deleteHydroReport(path, res=> {
            console.log(res);
            }));
          });
        }
        return onClose();
      };
      return (
      <Dialog open={open} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <DialogTitle onClick={() => console.log({values, errors})}>Test Charts</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} className={classes.report}>
            <Grid item xs={12} sm={7}>
              <TextField
                name="chart"
                label="Chart Number"
                disabled={Boolean(values.report)}
                value={values.chart}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.chart && errors.chart)}
                helperText={touched.chart && errors.chart}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                name="date"
                label="Date"
                disabled={Boolean(values.report)}
                value={values.timestamp ?
                  moment(values.timestamp).format('YYYY-MM-DD')
                  :values.date}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.date && errors.date)}
                helperText={touched.date && errors.date}
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                name="tester"
                label="Tester"
                disabled={Boolean(values.report)}
                value={values.tester}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.tester && errors.tester)}
                helperText={touched.tester && errors.tester}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                name="targetWp"
                label="Target Working Pressure"
                type="number"
                disabled={Boolean(values.report)}
                value={values.targetWp}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.targetWp && errors.targetWp)}
                helperText={touched.targetWp && errors.targetWp}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
              <Grid item xs={12}>
              <Dropzone 
                  onDrop={files => {
                  const handleDispatch = ({reports, replace}) => {
                    if (!edit) return;
                    return dispatch(itemActions.editHydroReport(edit.id, { ...edit, reports, jobId, replace }));
                  }
                    files[0]
                    && dispatch(itemActions.uploadHydroReport(jobId, files[0], ({ progress, error, url, name, path, fullPath}) => {
                      if (error) enqueueSnackbar(error, {variant: 'error'});
                      if (progress) setProgess(progress);
                      if (url) {
                        setProgess('');
                        if (values.reports) values.reports.push({ url, name, path, fullPath});
                        const reports = values.reports || [{ url, name, path, fullPath}];
                        setFieldValue('reports', reports);
                        edit
                        && values.report
                        ? confirm({
                          title: "Replace existing chart?",
    description: "Would you like to replace the current file or add to the file to the list of reports",
    confirmationText: "Replace File",
    cancellationText: "Add File",
                        }).then(() => handleDispatch({reports, replace: true})).catch(() => handleDispatch({reports, replace: false}))
                        : handleDispatch({reports, replace: true})
                      }
                    }));
                    
                  }}
                  accept='application/pdf'
                  onDropRejected={() => enqueueSnackbar('Only single .pdf files are allowed', { variant: 'warning'})}
                  multiple={false}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  }) => (
                    <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <TextField
                      className={clsx({
                        [classes.accept]: isDragAccept,
                        [classes.reject]: isDragReject
                      })}
                      label="Upload Test Chart"
                      // value={progress ? <LinearProgress variant="determinate" value={progress} /> : ''}
                      placeholder={!values.report  
                      ? "Click or drop files here to upload..."
                      : "Upload a file to replace the chart already on file..."}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    </div>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={12}>
                {/*<LinkHydro jobId={jobId} />*/}
              </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Charts</Typography>
              <List>
                {values.report && 
                <ListItem
                  component="a"
                  button
                  href={values.report}
                  target="_blank"
                >
                  {values.chart}
                </ListItem>}
                {values.reports &&
                map(values.reports, ({ url, name, path }, rIndex) => {
                  return (
                    <ListItem
                      key={url}
                      component="a"
                      button
                      href={url}
                      target="_blank"
                    >
                      {name}
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => {
                          confirm({
                            description: 'Are you sure you want to delete this report file?'
                          })
                          .then(() => {
                            dispatch(itemActions.deleteHydroReport(path, err => {
                              if (err) return enqueueSnackbar(err, { variant: 'error' });
                              values.reports.splice(rIndex, 1);
                              setFieldValue('reports', values.reports);
                              edit
                              && dispatch(itemActions.editHydroReport(edit.id, { ...edit, reports: values.reports }));
                              enqueueSnackbar('File deleted!', { variant: 'warning' });
                            }));
                          });
                        }}>
                          <DeleteForever />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
                {progress &&  
                  <ListItem>
                    <div className={classes.progress}>
                      <LinearProgress variant="determinate" value={progress || 0} />
                    </div>
                  </ListItem>
                }
              </List>
            </Grid>
          </Grid>
          <List>
            {map({...values.items, ...items }, (item, itemId) => {
              return (
                <ListItem key={itemId}>
                  <Checkbox
                    checked={Boolean(values.items && values.items[itemId] && !values.items[itemId].associated) || false}
                    disabled={Boolean(values.report)}
                    onChange={() => {
                      setFieldValue('items', 
                        values.items[itemId] ?
                        omit(values.items, itemId) || {}
                        : { ...values.items, [itemId]: items[itemId] }
                        )
                    }}
                  />
                  <ListItemText
                    primary={item.asset}
                    secondary={item.description}
                  />
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{values.report ? 'Close' : 'Cancel'}</Button>
          {!values.report && <Button type="submit" color="primary">{edit ? 'Update' : 'Submit'}</Button>}
        </DialogActions>
      </Form>
      </Dialog>
      )}}
    </Formik>
  );
};

const Charts = ({
    open,
    onClose,
    editCharts,
    items,
    jobId,
    lineItem
}) => {
  const classes = useStyles()
  const chartReports = () => (mapValues(mapKeys(editCharts, chart => chart.id), (chart) => {
      return Boolean(chart.includeOnReport && chart.includeOnReport[lineItem])
    }))
  const [openChart, setOpenChart] = React.useState(null);
  const [onReport, setOnReport] = React.useState(chartReports());
  
  React.useEffect(() => {
    return setOnReport(chartReports())
  }, [editCharts]) 
  const dispatch = useDispatch();
  return (
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          Link or Enter Hydrotest
          <LinkHydro jobId={jobId} />
          <IconButton
            className={classes.add}
            onClick={() => setOpenChart(true)}
            color="primary"
          >
            <Add />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" className={classes.sub}>
            Select to include on certificate:
          </Typography>
          <List>
          {map(editCharts, (editItem) => {
            return <ListItem key={editItem.id}> 
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={onReport[editItem.id]}
                disabled={!Boolean(lineItem)}
                disableRipple
                onChange={() => {
                  dispatch(itemActions.includeChartonReport(jobId, editItem.id, lineItem));
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={editItem.chart}
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => setOpenChart(editItem.id)}
              >
                <Create />
              </IconButton>
            </ListItemSecondaryAction>
            </ListItem>
          })}
          </List>
          <Hydro
            open={!!openChart}
            onClose={() => setOpenChart(null)}
            edit={find(editCharts, v => v.id === openChart)}
            items={items}
            jobId={jobId}
          />
        </DialogContent>
      </Dialog>
    )
}


export default Charts;



