import { map, find } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  LinearProgress,
  Avatar,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Check, Add } from '@material-ui/icons';
import { blue, green, red, grey } from '@material-ui/core/colors';
import Dropzone from 'react-dropzone';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import * as actions from 'actions/itemActions.js';


const useStyles = makeStyles(theme => ({
  '@global': {
  },
  accept: {
    backgroundColor: green[100],
  },
  reject: {
    backgroundColor: red[100]
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  image: {
    //width: 450,
    //maxHeight: 550,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      maxHeight: 'calc(50vh - 5px)'
    }
  },
  thumbnails: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    maxWidth: 400,
    paddingTop: theme.spacing(1),
    overflow: 'hidden',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: '0.4em'
    },
  },
  thumbnail: {
    margin: theme.spacing(1),
    // border: '1px solid gray'
  },
  thumb: {
    position: 'relative'
  },
  use: {
    boxShadow: `0 0 6px ${blue[900]}`,
  },
  unUsed: {
    boxShadow: `0 0 6px ${grey[900]}`,
  },
  iconButton: {
    height: 21,
    width: 21,
    padding: 1,
    '& svg' : {
      width: '.5em',
      height: '.5em',
    },
    position: 'absolute',
    top: -3,
    right: -3,
    border: `1px solid ${theme.palette.background.paper}`,
    fontWeight: 'bold',
  },
  iconButtonUse: {
    backgroundColor: blue[600],
    color: theme.palette.background.paper,
    '&:hover': {
       backgroundColor: blue[800]
    }
  },
  iconButtonUnused: {
    backgroundColor: grey[600],
    color: theme.palette.background.paper,
    '&:hover': {
       backgroundColor: grey[800]
    }
  }
}));

const AddPhotos = props => {
  const {
    open,
    onClose,
    item: {
      asset,
      images,
      indicationPictures
    },
    itemId,
    addItemPhoto,
    removeFromCert,
    indication
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgess] = React.useState(null);
  const [current, setCurrent] = React.useState({});
  React.useEffect(() => setCurrent(find(images, 'url') || {}) , [images]);
  const imageGroup = indication ? indicationPictures : images
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`Add ${indication ? 'Indication' : ''}Images for ${asset}`}</DialogTitle>
      <div className={classes.image} ><img src={current.url} alt="Asset Img"/></div>
      <div className={classes.thumbnails}>
        {map(imageGroup, (image, imageId) => {
          return (
            <div key={imageId} className={classes.thumb}>
              <Avatar
                variant="rounded"
                src={image.reportUrl}
                className={clsx({
                  [classes.thumbnail]: true,
                  [classes.use]: image.useOnReport,
                  [classes.unUsed]: !image.useOnReport,
                })}
                onClick={() => setCurrent(image)}
              />
              <Tooltip title={image.useOnReport ?"Remove from Certificate" : "Add to Certificate"}>
              <IconButton
                className={clsx({
                  [classes.iconButton]: true,
                  [classes.iconButtonUse]: image.useOnReport,
                  [classes.iconButtonUnused]: !image.useOnReport
                })}
                color="primary"
                onClick={() => removeFromCert(itemId, imageId, indication)}
              >
                {image.useOnReport ? <Check /> : <Add />}
              </IconButton>
              </Tooltip>
            </div>
          )
        })}
      </div>
      <DialogContent>
        <Dropzone 
          onDrop={files => {
            files[0] && addItemPhoto(itemId, files[0], ({ progress, error, url }) => {
              if (error) enqueueSnackbar(error, {variant: 'error'});
              if (progress) setProgess(progress);
              if (url) {
                setProgess(null);
              }
            }, indication);
            
          }}
          accept='image/*'
          onDropRejected={() => enqueueSnackbar('Only single image files are allowed', { variant: 'warning'})}
          multiple={false}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => (
            <div {...getRootProps()}>
            <input {...getInputProps()} />
            <TextField
              className={clsx({
                [classes.accept]: isDragAccept,
                [classes.reject]: isDragReject
              })}
              label="Upload Asset Photos"
              // value={progress ? <LinearProgress variant="determinate" value={progress} /> : ''}
              placeholder="Click or drop files here to upload..."
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            </div>
          )}
        </Dropzone>
        {progress &&
          <div className={classes.progress}>
            <LinearProgress variant="determinate" value={progress || 0} />
          </div>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onClose} variant="contained" color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};


export default connect(null, actions)(AddPhotos);


