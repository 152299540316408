import _, { map, includes, min, max, isEmpty, size } from "lodash";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { CSVDownload } from 'react-csv';
import { v4 as uuidV4 } from "uuid";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WarningIcon from "@material-ui/icons/Warning";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import moment from "moment";
import { createExcelFile } from "actions/jobActions";
import { read, utils, writeFile } from "xlsx";
import { Formik, Form } from "formik";
import { amber, green } from "@material-ui/core/colors";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";

import { storage } from "firebase_config";

import {
  // editRBReport,
  // editMPIReport,
  editUTTReport,
  // editThreadReport,
  editHydroReport,
} from "actions/itemActions";

const Signatures = ({ jobId }) => {
  const dispatch = useDispatch();
  const { utt, mpi, hydro, thread, rebuild } = useSelector((s) => s);

  const [signatures, setSignatures] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const ref = storage.ref("/users/signatures");
      const files = await ref.listAll().then(async (res) => {
        const urls = await Promise.all(
          map(res.items, async (itemRef) => {
            const url = await itemRef.getDownloadURL();
            return {
              name: itemRef.name,
              url,
            };
          })
        );
        return urls;
      });
      setSignatures(files);
      return;
    };
    fetch();
  }, []);
  return (
    <Box>
      <Typography variant="h5">Signatures</Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {includes(
            map(utt, ({ signature }) => Boolean(signature)),
            false
          ) ? (
            <WarningIcon style={{ color: amber[500], marginRight: 8 }} />
          ) : (
            <DoneAllIcon style={{ color: green[500], marginRight: 8 }} />
          )}
          Visual/UTT
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {map(utt, (report, reportId) => {
              return (
                <Grid item xs={12} key={reportId} style={{ marginBottom: 12 }}>
                  <TextField
                    select
                    fullWidth
                    label={`Report: ${report.report}`}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={signatures[0] && report.signature || ""}
                    helperText={`Inspector Name: ${report.inspector}`}
                    onChange={(e) => {
                      const signature = e.target.value;
                      dispatch(
                        editUTTReport(
                          reportId,
                          { ...report, signature },
                          (f) => f
                        )
                      );
                    }}
                  >
                    <MenuItem value="" />
                    {map(signatures, (signature) => {
                      return (
                        <MenuItem key={signature.url} value={signature.url}>
                          {signature.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {includes(
            map(hydro, ({ signature }) => Boolean(signature)),
            false
          ) ? (
            <WarningIcon style={{ color: amber[500], marginRight: 8 }} />
          ) : (
            <DoneAllIcon style={{ color: green[500], marginRight: 8 }} />
          )}
          Hydrotest
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {map(hydro, (report, reportId) => {
              return (
                <Grid item xs={12} key={reportId} style={{ marginBottom: 12 }}>
                  <TextField
                    select
                    fullWidth
                    label={`Chart: ${report.chart}`}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={signatures[0] && report.signature  || ""}
                    helperText={`Tester Name: ${report.tester}`}
                    onChange={(e) => {
                      const signature = e.target.value;
                      dispatch(
                        editHydroReport(
                          reportId,
                          { ...report, signature, jobId },
                          (f) => f
                        )
                      );
                    }}
                  >
                    {map(signatures, (signature) => {
                      return (
                        <MenuItem key={signature.url} value={signature.url}>
                          {signature.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/*<Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {includes(map(thread, ({signature}) => Boolean(signature)), false)
            ? <WarningIcon style={{color: amber[500], marginRight: 8}}/>
            : <DoneAllIcon style={{color: green[500], marginRight: 8}}/>
          }
          Dimensional (Thread)
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {map(thread, (report, reportId) => {
              return (
                <Grid item xs={12} key={reportId} style={{marginBottom: 12}}>
                  <TextField
                    select
                    fullWidth
                    label={`Report: ${report.report}`}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={report.signature || ""}
                    helperText={`Inspector Name: ${report.inspector} ${reportId}`}
                    onChange={e => {
                    const signature = e.target.value;
                      dispatch(editThreadReport(reportId, { ...report, signature }, f=>f))
                    }}
                  >
                    {map(signatures, (signature) => {
                      return (
                        <MenuItem key={signature.url} value={signature.url}>
                          {signature.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {includes(map(rebuild, ({signature}) => Boolean(signature)), false)
            ? <WarningIcon style={{color: amber[500], marginRight: 8}}/>
            : <DoneAllIcon style={{color: green[500], marginRight: 8}}/>
          }
          Rebuild
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {map(rebuild, (report, reportId) => {
              return (
                <Grid item xs={12} key={reportId} style={{marginBottom: 12}}>
                  <TextField
                    select
                    fullWidth
                    label={`Report: ${report.report}`}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={report.signature || ""}
                    helperText={`Inspector Name: ${report.rebuilder}`}
                    onChange={e => {
                    const signature = e.target.value;
                      dispatch(editRBReport(reportId, { ...report, signature }, f=>f))
                    }}
                  >
                    {map(signatures, (signature) => {
                      return (
                        <MenuItem key={signature.url} value={signature.url}>
                          {signature.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {includes(map(mpi, ({signature}) => Boolean(signature)), false)
            ? <WarningIcon style={{color: amber[500], marginRight: 8}}/>
            : <DoneAllIcon style={{color: green[500], marginRight: 8}}/>
          }
          Mag Particle
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {map(mpi, (report, reportId) => {
              return (
                <Grid item xs={12} key={reportId} style={{marginBottom: 12}}>
                  <TextField
                    select
                    fullWidth
                    label={`Report: ${report.report}`}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={report.signature || ""}
                    helperText={`Inspector Name: ${report.inspector}`}
                    onChange={e => {
                    const signature = e.target.value;
                      dispatch(editRBReport(reportId, { ...report, signature }, f=>f))
                    }}
                  >
                    {map(signatures, (signature) => {
                      return (
                        <MenuItem key={signature.url} value={signature.url}>
                          {signature.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>*/}
    </Box>
  );
};

const getItemData = (reports, items) => {
  const { utt, rebuild, hydro, mpi, thread } = reports;
  const formatDate = (date) => date && date.slice(5) + "-" + date.slice(0, 4);
  const xlxsInitialValues = map(items, (item, itemId) => {
    const hasUTT = () => {
      const result = _.find(
        utt,
        (report) => report.items[itemId] && report.items[itemId].readings
      );
      const exists = _.size(result) > 0 ? result : {};
      return exists;
    };

    const hasVisual = () => {
      const result = _.find(
        utt,
        (report) => report.items[itemId] && report.items[itemId].visual
      );
      const exists = _.size(result) > 0 ? result : {};
      return exists;
    };

    const hasRb = () => {
      const result = _.find(rebuild, (report) => report.items[itemId]);
      const exists = _.size(result) > 0 ? result : {};
      return exists;
    };

    const hasMPI = () => {
      const result = _.find(mpi, (report) => report.items[itemId]);
      const exists = _.size(result) > 0 ? result : {};
      return exists;
    };

    const hasThread = () => {
      const result = _.find(thread, (report) => report.items[itemId]);
      const exists = _.size(result) > 0 ? result : {};
      return exists;
    };

    const hasHydro = () => {
      const result = _.find(hydro, (report) => report.items[itemId]);
      const exists = _.size(result) > 0 ? result : {};
      return {
        ...exists,
        date:
          formatDate(exists.date) ||
          moment(exists.timestamp).format("MM-DD-YYYY"),
      };

      // const searchAsset = items && items[itemId] && items[itemId].asset;
      // const result = _(hydro)
      //   .pickBy((report) => {
      //     const isItemId = Boolean(
      //       report.items &&
      //         report.items[itemId] &&
      //         !report.items[itemId].associated
      //     );
      //     const hasAssetNumber = Boolean(
      //       _.find(report.items, (chartItem) => chartItem.asset === searchAsset)
      //     );
      //     const include =
      //       report.includeOnReport && report.includeOnReport[itemId];
      //     return Boolean(include && (isItemId || hasAssetNumber));
      //   })

      //   // .map(info => {
      //   //   return Boolean((info && !info.associated) || (info && info.asset === searchAsset))

      //   // })
      //   .value();
      // const pullData = () => {
      //   const exists = _.size(result) > 0 ? result : {};
      //   const existsData = _.map(_.values(exists), (test, testIndex) => {
      //     return {
      //       [`hydroTester${testIndex + 1}`]: test.tester || "",
      //       [`hydroChart${testIndex + 1}`]: test.chart || "",
      //       [`hydroPressure${testIndex + 1}`]: test.targetWp || "",
      //       [`hydroDate${testIndex + 1}`]:
      //         formatDate(test.date) ||
      //         moment(test.timestamp).format("MM-DD-YYYY"),
      //     };
      //   });
      //   return _.flattenDeep(existsData);
      // };
      // return pullData();
    };
    const hydroData = hasHydro();
    const rebuildData = hasRb();
    const threadData = hasThread();
    const visualData = hasVisual();
    const uttData = hasUTT();
    const mpiData = hasMPI();
    const temps = hydroData && map(hydroData.holds, (hold) => hold.temperature);
    const airTemps = hydroData && map(hydroData.holds, (hold) => hold.airTemp);
    const tempRange = temps ? `${min(temps)}℉-${max(temps)}℉` : "N/A";
    const airTempRange = airTemps
      ? `${min(airTemps)}℉-${max(airTemps)}℉`
      : "N/A";
    return {
      company: "Gulf-Pro Services",
      phone: "985-879-1002",
      address: "1537 Coteau Rd., Houma, LA 70364",
      po: "",
      descripton: item.description || "",
      asset: item.asset || "",
      serial: item.serial || "",
      workingPressure: item.wp || "",
      testPressure: item.tp || "",
      tempRange,
      hydWorkingPressure: "",
      hydTestPressure: "",
      swl: "",
      pullTestLoad: "",
      pullTestCert: "",
      service: "standard",
      dataPack: false,
      tornDown: isEmpty(rebuildData),
      dimensional: isEmpty(threadData),
      visual: isEmpty(visualData),
      utt: isEmpty(uttData),
      inspector: uttData.inspector || "",
      inspectionDate: uttData.inspector || "",
      inspectorSignature: uttData.signature || "",
      mpi: isEmpty(mpiData),
      loadTest: false,
      rebuilt: isEmpty(rebuildData),
      mtr: false,
      pressureTest: isEmpty(hydroData),
      functioned: isEmpty(rebuildData),
      paint: false,
      cos: false,
      banded: true,
      hydroDate: "",
      hydroTester: "",
      hydroDate: "",
      hydroStartDate: "",
      hydroStopDate: "",
      hydroStartTime: "",
      hydroStopTime: "",
      recorder: "",
      recorderCal: "",
      temperature: airTempRange,
      testerSignature: hydroData.signature || "",
      qc: "Nick Parria",
    };
  });
  return xlxsInitialValues;
};

const FormMergeDialog = ({ jobId }) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const {
    items: _items,
    utt,
    rebuild,
    hydro,
    mpi,
    thread,
    jobs,
  } = useSelector((s) => s);
  const reports = { utt, rebuild, hydro, mpi, thread };

  const items = getItemData(reports, _items);
  // const createXLSX = () => {
  //   const wb = utils.book_new();
  //   const ws = utils.json_to_sheet([values]);
  //   utils.book_append_sheet(wb, ws, "Sheet1");
  //   return writeFile(wb, "SheetJSAddAOA.xlsx");
  // };

  return (
    <Dialog open={open} onClose={() => setOpen((p) => !p)}>
      <DialogContent>
        {/*<Button onClick={createXLSX}>Create Excel File</Button>*/}
        <Signatures jobId={jobId} />
        {page}
        {/*items[page] && <ItemMergeForm
          jobId={jobId}
          item={items[page]}
        />*/}
        <Pagination count={size(items)} page={page} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button>Next</Button>
      </DialogActions>
    </Dialog>
  );
};

const JobCSV = ({ jobId }) => {
  const downloadLinkRef = useRef(null);
  const dispatch = useDispatch();
  const [dl, setDl] = useState(false);
  const [sessionId, setSessionId] = useState(uuidV4());
  const [working, setWorking] = useState("");

  const { items, utt, rebuild, hydro, mpi, thread, jobs } = useSelector(
    (s) => s
  );

  const job = jobs[jobId];

  const formatDate = (date) => date && date.slice(5) + "-" + date.slice(0, 4);

  const hasUTT = (itemId) => {
    const result = _.find(
      utt,
      (report) => report.items[itemId] && report.items[itemId].readings
    );
    const exists = _.size(result) > 0 ? result : {};
    const existsData = {
      uttInspector: exists.inspector || "",
      uttReport: exists.report || "",
      uttDate: formatDate(exists.date) || "",
    };
    return existsData;
  };

  const hasVisual = (itemId) => {
    const result = _.find(
      utt,
      (report) => report.items[itemId] && report.items[itemId].visual
    );
    const exists = _.size(result) > 0 ? result : {};
    const existsData = {
      visualInspector: exists.inspector || "",
      visualReport: exists.report || "",
      visualDate: formatDate(exists.date) || "",
    };
    return existsData;
  };

  const hasRb = (itemId) => {
    const result = _.find(rebuild, (report) => report.items[itemId]);
    const exists = _.size(result) > 0 ? result : {};
    const existsData = {
      rebuildTech: exists.rebuilder || "",
      rebuildReport: exists.report ? `'${exists.report}'` : "",
      rebuildDate: formatDate(exists.date) || "",
    };
    return existsData;
  };

  const hasMPI = (itemId) => {
    const result = _.find(mpi, (report) => report.items[itemId]);
    const exists = _.size(result) > 0 ? result : {};
    const existsData = {
      mpiInspector: exists.inspector || "",
      mpiReport: exists.report || "",
      mpiDate: formatDate(exists.date) || "",
    };
    return existsData;
  };

  const hasThread = (itemId) => {
    const result = _.find(thread, (report) => report.items[itemId]);
    const exists = _.size(result) > 0 ? result : {};
    const existsData = {
      threadInspector: exists.inspector || "",
      threadReport: exists.report || "",
      threadDate: formatDate(exists.date) || "",
    };
    return existsData;
  };

  const hasHydro = (itemId) => {
    const searchAsset = items && items[itemId] && items[itemId].asset;
    const result = _(hydro)
      .pickBy((report) => {
        const isItemId = Boolean(
          report.items &&
            report.items[itemId] &&
            !report.items[itemId].associated
        );
        const hasAssetNumber = Boolean(
          _.find(report.items, (chartItem) => chartItem.asset === searchAsset)
        );
        const include =
          report.includeOnReport && report.includeOnReport[itemId];
        return Boolean(include && (isItemId || hasAssetNumber));
      })

      // .map(info => {
      //   return Boolean((info && !info.associated) || (info && info.asset === searchAsset))

      // })
      .value();
    const pullData = () => {
      const exists = _.size(result) > 0 ? result : {};
      const existsData = _.map(_.values(exists), (test, testIndex) => {
        return {
          [`hydroTester${testIndex + 1}`]: test.tester || "",
          [`hydroChart${testIndex + 1}`]: test.chart || "",
          [`hydroPressure${testIndex + 1}`]: test.targetWp || "",
          [`hydroDate${testIndex + 1}`]:
            formatDate(test.date) ||
            moment(test.timestamp).format("MM-DD-YYYY"),
        };
      });
      return _.flattenDeep(existsData);
    };
    return pullData();
  };

  const csvData = () =>
    _.map(items, (item, itemId) => {
      const csvHydro = hasHydro(itemId)[0];
      const csvMPI = hasMPI(itemId);
      const csvUTT = hasUTT(itemId);
      const csvThread = hasThread(itemId);
      const csvRB = hasRb(itemId);
      const csvVisual = hasVisual(itemId);
      return {
        asset: item.asset,
        description: item.description.replace(/,/g, ""),
        fail: item.fail || null,
        ...csvHydro,
        ...csvVisual,
        ...csvUTT,
        ...csvMPI,
        ...csvThread,
        ...csvRB,
      };
    });

  const handleDownload = (hydroOnly) => {
    const data = csvData();
    setWorking(hydroOnly ? "hydroOnly" : "all");
    dispatch(createExcelFile(jobId, sessionId, data, hydroOnly));
  };

  return <Button onClick={handleDownload}>Download Excel</Button>;
};

export default FormMergeDialog;
