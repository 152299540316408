import { pickBy, includes, map, lowerCase } from 'lodash';
import { database } from 'firebase_config';

export const FETCH_JOBS = 'FETCH_JOBS';
export const FETCH_JOB_ITEMS = 'FETCH_JOB_ITEMS';
export const FETCH_CUSTOMER_ITEMS = 'FETCH_CUSTOMER_ITEMS';
export const FILTERED_JOBS = 'FILTERED_JOBS';

const jobs = database.ref('repair_work/jobs');
const items = database.ref('repair_work/items');


export function fetchJobs(customer) {
  const jobsFilter = customer
  ? jobs.orderByChild('customer').equalTo(customer.name)
  : jobs.orderByChild('status').equalTo('open');
  const fetchItems = async dispatch => {
    return items.orderByChild('customer').equalTo(customer.name).once('value').then(s => {
      const items = s.val() ? s.val() : {}
      return dispatch({
        type: FETCH_CUSTOMER_ITEMS,
        payload: items
      })
    })
  }
  return dispatch => {
    jobsFilter.on('value', snapshot => {
      dispatch({
        type: FETCH_JOBS,
        payload: snapshot ? snapshot.val() : {}
      });
    });
    return customer && fetchItems(dispatch)
  }
}

export function createExcelFile(jobId, sessionId, data, hydroOnly) {
  return () => {
    jobs.child(`${jobId}/excelData/${sessionId}`).set({ data, hydroOnly });
  }
}

export function bugLog(values) {
  console.log(values)
  return () => database.ref('repair_work/debug').child('jobs').push(values)
}

export function searchJobs(value) {
  return (dispatch, getState) => {
    const {jobs, customerItems} = getState();
    const canRender = pickBy(jobs, (job, jobId) => {
      const findItems = pickBy(customerItems, item => includes(lowerCase(item.asset), lowerCase(value)));
      const ids = map(findItems, i => i.jobId);
      return includes(ids, jobId)
      
    })
    return dispatch({
      type: FILTERED_JOBS,
      payload: value ? canRender : null
    })
  }
}
// export function fetchJobItems(jobId) {
//   return dispatch => items.orderByChild('job').equalTo(jobId).on('value', snapshot => {
//     dispatch({
//       type: FETCH_JOB_ITEMS,
//       payload: snapshot ? snapshot.val() : {}
//     })
//   })
// }

export function createJob(values, cb) {
  return () => jobs.push(values).then(cb);
}

export function editJob(jobId, values, cb) {
  return () => jobs.child(jobId).update(values).then(cb);
}

export function closeJob(jobId, cb) {
  return () => jobs.child(jobId).child('status').transaction(status => status === 'open' ? 'closed' : 'open').then(cb);
}