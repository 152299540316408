import React from 'react';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  useTheme
} from '@material-ui/core';

import * as actions from 'actions/customerActions';

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required('First name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phoneNumber: Yup.string()
    .matches(/^\+[1-9]\d{1,14}$/, 'Phone number must be country code (+1) then 10 digits')
    .required('Phone number is required'),
});

const ContactFormDialog = ({
  edit,
  setEdit,
  writeCustomerContact,
  customerId,
  customers
}) => {
  const [open, setOpen] = React.useState(false);
  const [editValues, setEditValues] = React.useState(null)
  const theme = useTheme()
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setEdit) {
      setEdit(null);
      setEditValues(null);
    }
  };
  
  React.useEffect(() => {
    if (edit) {
      setEditValues(customers?.[customerId]?.contacts?.[edit] || null)
      setOpen(true);
    }
  }, [edit]);
  
  const handleSubmit = (values) => {
    writeCustomerContact(customerId, values, handleClose)
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1)
      }}
    >
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add Contact
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Contact Information</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={editValues || { displayName: '', email: '', phoneNumber: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
              setSubmitting(false);
              handleClose();
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent>
                <DialogContentText>
                  Please fill in customer contact details.
                </DialogContentText>
                <Field
                  as={TextField}
                  autoFocus
                  margin="dense"
                  variant="outlined"
                  id="displayName"
                  name="displayName"
                  label="Full Name"
                  type="text"
                  fullWidth
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
                <Field
                  as={TextField}
                  margin="dense"
                  variant="outlined"
                  id="email"
                  name="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <Field
                  as={TextField}
                  margin="dense"
                  variant="outlined"
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone Number"
                  type="tel"
                  fullWidth
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
};

function mapStateToProps({customers}) {
  return { customers }
}
export default connect(mapStateToProps, actions)(ContactFormDialog);