import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import * as actions from 'actions/itemActions.js';

import { useConfirm } from 'components/confirm';

// const useStyles = makeStyles(() => ({
  
// }));


const UploadItemList = props => {
  const {
    open,
    onClose,
    cloneList,
    clone
  } = props;
  // const classes = useStyles();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [list, setList] = React.useState('');
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Clone List of Items</DialogTitle>
      <DialogContent>
        <TextField
          value={list}
          onChange={e => setList(e.target.value)}
          multiline
          fullWidth
          variant="outlined"
          onKeyDown={(e) => { if (e.keyCode === 9) {
            e.preventDefault();
            setList(list + '\t')
          }}} 
        />
        {list &&
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Asset</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>OEM</TableCell>
              <TableCell>OEM Serial</TableCell>
            </TableRow>
          </TableHead>
            {list.split('\n').map((row, index) => {
              return <TableRow key={index}>
                {row.split('\t').map((cell, index) => (
                  <TableCell key={cell+index}>{cell}</TableCell>
                ))}
              </TableRow>
            })}
        </Table>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {list && <Button
          onClick={() => confirm({
          }).then(() => cloneList(list, clone, (asset) => {
            enqueueSnackbar(`${asset} added`, { variant: 'success'});
            onClose();
          }))}
          variant="contained"
          color="primary">
            Add Items
          </Button>}
      </DialogActions>
    </Dialog>
  );
};



export default connect(null, actions)(UploadItemList);


