import _ from 'lodash';
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { CSVDownload } from 'react-csv';
import { v4 as  uuidV4 } from "uuid";
import { withStyles, makeStyles  } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { keyframes } from '@emotion/react';
import moment from "moment";
import { createExcelFile } from 'actions/jobActions';


const revolvingShadow = keyframes`
  0% { box-shadow: 7px 0 7px rgba(63, 81, 181, 0.8); }
  12.5% { box-shadow: 5px 5px 7px rgba(63, 81, 181, 0.8); }
  25% { box-shadow: 0 7px 7px rgba(63, 81, 181, 0.8); }
  37.5% { box-shadow: -5px 5px 7px rgba(63, 81, 181, 0.8); }
  50% { box-shadow: -7px 0 7px rgba(63, 81, 181, 0.8); }
  62.5% { box-shadow: -5px -5px 7px rgba(63, 81, 181, 0.8); }
  75% { box-shadow: 0 -7px 7px rgba(63, 81, 181, 0.8); }
  87.5% { box-shadow: 5px -5px 7px rgba(63, 81, 181, 0.8); }
  100% { box-shadow: 7px 0 7px rgba(63, 81, 181, 0.8); }
`;

const useStyles = makeStyles({
  working: {
    animation: `revolving-shadow 1s infinite linear`,
  },
});

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    margin: 0,
    minHeight: 0,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    // marginBottom: -1,
    // minHeight: 56,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    minHeight: 0,
    '&$expanded': {
      margin: 0,
    minHeight: 0,
    },
  },
  expanded: {
    padding: 0,
    margin: 0,
    minHeight: 0,
    
  },
})(MuiAccordionSummary)

  
const JobCSV = ({jobId}) => {
  const downloadLinkRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dl, setDl] = useState(false);
  const [sessionId, setSessionId] = useState(uuidV4());
  const [working, setWorking] = useState('');
  
  const {
    items,
    utt,
    rebuild,
    hydro,
    mpi,
    thread,
    jobs
  } = useSelector(s=>s);
  
  const job = jobs[jobId];
  
  const formatDate = date => date && date.slice(5) + "-" + date.slice(0, 4);
  
  const hasUTT = (itemId) => {
    const result = _.find(
      utt,
      (report) => report.items[itemId] && report.items[itemId].readings
    );
        const exists = _.size(result) > 0 ? result : {};
        const existsData = {
            uttInspector: exists.inspector || "",
            uttReport: exists.report || "",
            uttDate: formatDate(exists.date) || ""
          }
    return existsData;
  };

  const hasVisual = (itemId) => {
    const result = _.find(
      utt,
      (report) => report.items[itemId] && report.items[itemId].visual
    );
        const exists = _.size(result) > 0 ? result : {};
        const existsData = {
            visualInspector: exists.inspector || "",
            visualReport: exists.report || "",
            visualDate: formatDate(exists.date) || ""
          }
    return existsData;
  };

  const hasRb = (itemId) => {
    const result = _.find(rebuild, (report) => report.items[itemId]);
        const exists = _.size(result) > 0 ? result : {};
        const existsData = {
            rebuildTech: exists.rebuilder || "",
            rebuildReport: exists.report ? `'${exists.report}'` : "",
            rebuildDate: formatDate(exists.date) || ""
          }
    return existsData;
  };

  const hasMPI = (itemId) => {
    const result = _.find(mpi, (report) => report.items[itemId]);
        const exists = _.size(result) > 0 ? result : {};
        const existsData = {
            mpiInspector: exists.inspector || "",
            mpiReport: exists.report || "",
            mpiDate: formatDate(exists.date) || ""
          }
    return existsData;
  };

  const hasThread = (itemId) => {
    const result = _.find(thread, (report) => report.items[itemId]);
        const exists = _.size(result) > 0 ? result : {};
        const existsData = {
            threadInspector: exists.inspector || "",
            threadReport: exists.report || "",
            threadDate: formatDate(exists.date) || ""
          }
    return existsData;
  };

  const hasHydro = (itemId) => {
    const searchAsset = items && items[itemId] && items[itemId].asset;
    const result = _(hydro)
      .pickBy((report) => {
        const isItemId = Boolean(
          report.items &&
            report.items[itemId] &&
            !report.items[itemId].associated
        );
        const hasAssetNumber = Boolean(
          _.find(report.items, (chartItem) => chartItem.asset === searchAsset)
        );
        const include =
          report.includeOnReport && report.includeOnReport[itemId];
        return Boolean(include && (isItemId || hasAssetNumber));
      })

      // .map(info => {
      //   return Boolean((info && !info.associated) || (info && info.asset === searchAsset))

      // })
      .value();
      const pullData = () => {
        const exists = _.size(result) > 0 ? result : {};
        const existsData = _.map(_.values(exists), (test, testIndex) => {
          return {
            [`hydroTester${testIndex+1}`]: test.tester || "",
            [`hydroChart${testIndex+1}`]: test.chart || "",
            [`hydroPressure${testIndex+1}`]: test.targetWp || "",
            [`hydroDate${testIndex+1}`]: formatDate(test.date) || moment(test.timestamp).format("MM-DD-YYYY")
          }
        })
        return _.flattenDeep(existsData)
      }
    return pullData();
  };
  
  const csvData = () => _.map(items, (item, itemId) => {
    const csvHydro = hasHydro(itemId)[0];
    const csvMPI = hasMPI(itemId);
    const csvUTT = hasUTT(itemId);
    const csvThread = hasThread(itemId);
    const csvRB = hasRb(itemId);
    const csvVisual = hasVisual(itemId);
    //setTimeout(() =>setDl(false), 1500)
    return {
      asset: item.asset,
      description: item.description.replace(/,/g, ''),
      fail: item.fail || null,
      ...csvHydro,
      ...csvVisual,
      ...csvUTT,
      ...csvMPI,
      ...csvThread,
      ...csvRB
    } 
  })
  
  const handleDownload = (hydroOnly) => {
    const data = csvData();
    setWorking(hydroOnly ? 'hydroOnly' : 'all');
    dispatch(createExcelFile(jobId, sessionId, data, hydroOnly))
  }
  
  useEffect(() => {
    console.log('job changes', job)
    const sessionData = job?.excelData?.[sessionId];
    if (working && sessionData?.downloadURL) {
      setWorking('');// Assume the file becomes available now
      const fileURL = job.excelData[sessionId].downloadURL;
      const fileName = 'RecertData.pdf';
  
      const link = downloadLinkRef.current;
      link.href = fileURL;
      link.download = fileName;
      link.click();
    }
  }, [job])
 
 
  return (
    <Accordion style={{boxShadow: 'none', margin: 0, padding: 0}}>
      <AccordionSummary>
        Download Data
      </AccordionSummary>
      <AccordionDetails  >
      <List dense disablePadding>
        <ListItem button onClick={() => handleDownload(false)} className={working === 'all' ? classes.working : ''} disabled={Boolean(working)}>
          <ListItemText primary={working === 'all' ? "Creating Data File ..." : "Print All Data"} />
        </ListItem>
        {/* Hidden link element for triggering the download */}
        <a target="_blank" rel="noopener noreferrer" ref={downloadLinkRef} style={{ display: 'none' }} />
        <ListItem button onClick={() => handleDownload(true)} className={working === 'hydroOnly' ? classes.working : "" } disabled={Boolean(working)}>
          <ListItemText primary={working === 'hydroOnly' ? "Creating Data File ..." : "Print Hydro Data Only" } />
        </ListItem>
      </List>
      </AccordionDetails>
      {/*dl && <CSVDownload filename='recertCSVDwonload.csv' data={csvData()} target="_blank" />*/}
    </Accordion>
  )
}

export default JobCSV




