import { Font } from '@react-pdf/renderer'
import merriweather from 'fonts/Merriweather-BoldItalic.ttf';
import merriweatherItalic from 'fonts/Merriweather-Italic.ttf';
import domineBold from 'fonts/Domine-Bold.ttf';
import domine from 'fonts/Domine-Regular.ttf';

export const register = new Promise(async (res, rej) => {
  Font.register({
    family: 'Merriweather',
    fonts: [
      {src: merriweather, fontStyle: 'normal'},
      {src: merriweatherItalic, fontStyle: 'italic'}
    ]
  });
  
  Font.register({
    family: 'Domine',
    fonts: [
      {src: domine, fontWeight: 'normal'},
      {src: domineBold, fontWeight: 'bold'},
    ]
  });
  res();
})

