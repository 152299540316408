import { map, omit, size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  LinearProgress,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { DeleteForever } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';
import clsx from 'clsx';
import { object, string, mixed, array } from 'yup';

import * as actions from 'actions/itemActions.js';
import { useConfirm } from 'components/confirm';

const useStyles = makeStyles(theme => ({
  report: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  accept: {
    backgroundColor: green[100],
  },
  reject: {
    backgroundColor: red[100]
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(2),
  }
}));


const MPI = props => {
  const {
    open,
    onClose,
    edit,
    items,
    jobId,
    createMPIReport,
    editMPIReport,
    deleteMPIReport,
    uploadMPIReport
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [progress, setProgess] = React.useState('');
  return (
  <Formik
      enableReinitialize={Boolean(edit)}
      initialValues={edit ||{
        report: '',
        date: '',
        inspector: '',
        items: {},
        reports: [],
        jobId
      }}
      onSubmit={(values, actions) => {
        return edit ?
        editMPIReport(edit.id, values, () => {
          enqueueSnackbar('Report updated', { variant: 'success'});
          actions.resetForm();
          onClose();
        })
        :
        createMPIReport(values, () => {
          enqueueSnackbar('Report added', { variant: 'success'});
          actions.resetForm();
          onClose();
        });
      }}
      validationSchema={
        object().shape({
          report: string().required(),
          date: string().required(),
          inspector: string().required(),
          items: mixed().test({
            name: 'items',
            test: value => size(value) !== 0,
            message: 'Please select at least one item'
          }),
          reports: array().min(1).required()
        })
      }
    >
      {(formikProps) => {
      const {
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        resetForm
      } = formikProps;
      const handleClose = () => {
        if (!edit) map(values.reports, ({path}) => deleteMPIReport(path));
        resetForm();
        return onClose();
      };
      return (
      <Dialog open={open} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <DialogTitle>Magnetic Particle Inspection</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} className={classes.report}>
            <Grid item xs={12} sm={7}>
              <TextField
                name="report"
                label="Report"
                value={values.report}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.report && errors.report)}
                helperText={touched.report && errors.report}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                name="date"
                label="Date"
                value={values.date}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.date && errors.date)}
                helperText={touched.date && errors.date}
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="inspector"
                label="Inspector"
                value={values.inspector}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.inspector && errors.inspector)}
                helperText={touched.inspector && errors.inspector}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
              <Grid item xs={12}>
                <Dropzone 
                  onDrop={files => {
                    files[0] && uploadMPIReport(jobId, files[0], ({ progress, error, url, name, path, fullPath }) => {
                      if (error) enqueueSnackbar(error, {variant: 'error'});
                      if (progress) setProgess(progress);
                      if (url) {
                        setProgess('');
                        if (values.reports) values.reports.push({ url, name, path, fullPath });
                        const reports = values.reports || [{ url, name, path, fullPath }];
                        setFieldValue('reports', reports);
                        edit && editMPIReport(edit.id, { ...edit, reports });
                      }
                    });
                    
                  }}
                  accept='application/pdf'
                  onDropRejected={() => enqueueSnackbar('Only single .pdf files are allowed', { variant: 'warning'})}
                  multiple={false}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  }) => (
                    <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <TextField
                      className={clsx({
                        [classes.accept]: isDragAccept,
                        [classes.reject]: isDragReject
                      })}
                      label="Upload MPI Reports"
                      // value={progress ? <LinearProgress variant="determinate" value={progress} /> : ''}
                      placeholder="Click or drop files here to upload..."
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    </div>
                  )}
                </Dropzone>
              </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Reports</Typography>
              <List>
                {map(values.reports, ({ url, name, path }, rIndex) => {
                  return (
                    <ListItem
                      key={url}
                      component="a"
                      button
                      href={url}
                      target="_blank"
                    >
                      {name}
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => {
                          confirm({
                            description: 'Are you sure you want to delete this report file?'
                          })
                          .then(() => {
                            deleteMPIReport(path, err => {
                              if (err) return enqueueSnackbar(err, { variant: 'error' });
                              values.reports.splice(rIndex, 1);
                              setFieldValue('reports', values.reports);
                              edit && editMPIReport(edit.id, { ...edit, reports: values.reports });
                              enqueueSnackbar('File deleted!', { variant: 'warning' });
                            });
                          });
                        }}>
                          <DeleteForever />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
                {progress &&  
                  <ListItem>
                    <div className={classes.progress}>
                      <LinearProgress variant="determinate" value={progress || 0} />
                    </div>
                  </ListItem>
                }
              </List>
            </Grid>
          </Grid>
          <List>
            {map(items, (item, itemId) => {
              return (
                <ListItem key={itemId}>
                  <Checkbox
                    checked={Boolean(values.items && values.items[itemId]) || false}
                    onChange={() => {
                      setFieldValue('items', 
                        values.items[itemId] ?
                        omit(values.items, itemId) || {}
                        : { ...values.items, [itemId]: true}
                        )
                    }}
                  />
                  <ListItemText
                    primary={item.asset}
                    secondary={item.description}
                  />
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="primary">{edit ? 'Update' : 'Submit'}</Button>
        </DialogActions>
      </Form>
      </Dialog>
      )}}
    </Formik>
  );
};



export default connect(null, actions)(MPI);


