import React from 'react'
import {
  TextField,
  InputAdornment
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch } from 'react-redux';

import { searchJobs } from 'actions/jobActions';

const SearchCustomerJob = ({ customer }) => {
  const dispatch = useDispatch();
  const [input, setInput] = React.useState('');
  
  
  const handleChange = e => setInput(e.target.value);
  
  React.useEffect(() => {
    dispatch(searchJobs(input));
    return;
  }, [dispatch, input])
  return <TextField
    placeholder="Search for asset numbers..."
    value={input}
    onChange={handleChange}
    InputProps={{
      startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
    }}
  />
}


export default SearchCustomerJob;