import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
// import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import { ConfirmProvider } from 'components/confirm';
import rootReducer from 'reducers';
import 'index.css';
import SignIn from 'components/signin';
import Main from 'components/main';
import FlappyBall from 'components/flappyBall';
import PrivateRoute from 'components/auth';
import * as serviceWorker from 'serviceWorker';

import { verifyAuth } from './actions';

const theme = createTheme({
      palette: {
      },
    });

export function configureStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        compose (
            applyMiddleware(thunk),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    );
    
    store.dispatch(verifyAuth())

    return store;
}

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={4}>
            <ConfirmProvider>
                <ThemeProvider  theme={theme}>
                    <BrowserRouter>
                        <Switch>
                            <PrivateRoute exact path='/main' component={Main} />
                            <Route exact path='/flappy' component={FlappyBall} />
                            <Route path='/' component={SignIn} />
                        </Switch>
                    </BrowserRouter>
                </ThemeProvider>
            </ConfirmProvider>
        </SnackbarProvider>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
