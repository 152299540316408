import { FETCH_CUSTOMER_ITEMS } from 'actions/jobActions';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_CUSTOMER_ITEMS:
            return action.payload;
        default:
            return state;
    }
}