import { map, omit, size, isEqual, chain, indexOf } from 'lodash';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  LinearProgress,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  ListItemText,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Chip
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { green, red } from '@material-ui/core/colors';
import { DeleteForever } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';
import clsx from 'clsx';
import { object, string, mixed, array } from 'yup';

import * as actions from 'actions/itemActions.js';
import { useConfirm } from 'components/confirm';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 840,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    margin: 'auto'
  },
  divider: {
    marginBottom: theme.spacing(.5)
  },
  report: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  accept: {
    backgroundColor: green[100],
  },
  reject: {
    backgroundColor: red[100]
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  thread: {
    padding: theme.spacing(3)
  }
}));


const Rebuild = props => {
  const {
    open,
    onClose,
    edit,
    items,
    jobId,
    createThreadReport,
    editThreadReport,
    deleteThreadReport,
    uploadThreadReport
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [progress, setProgess] = React.useState('');
  const getProfiles = (items) => {
    return items && chain(items)
      .map(item => (item.profiles))
      .flatten()
      .map(item => ({ name: item.name, callout: item.profile, gauge: '' }))
      .uniqBy('name')
      .value();
  };
  
  const [alerts, setAlerts] = React.useState({})
  return (
  <Formik
      enableReinitialize={Boolean(edit)}
      initialValues={edit ||{
        report: '',
        date: '',
        inspector: '',
        reports: [],
        items: {},
        gauges: getProfiles(items),
        jobId
      }}
      onSubmit={(values, actions) => {
        const itemProfiles = chain(values.items)
            .map(item => (item))
            .flattenDepth(1)
            .map(item => item.name)
            .uniq()
            .value();
        // console.log('profs', itemProfiles)
        // itemProfiles.forEach(prof => {
        // const getGauge = find(profiles, {name:  prof});
        // console.log('getGauge', getGauge)
        //   setAlerts(getGauge && !getGauge.gauge &&
        //   { ...alerts, [prof]: `${prof} threads require a gauge serial number` }
        //   );
        // });
        map(values.gauges, gauge => {
            // console.log(gauge.name, indexOf(itemProfiles, gauge.name), itemProfiles)
            return setAlerts(!gauge.gauge && indexOf(itemProfiles, gauge.name)>0 ?
            { ...alerts, [gauge.name]: `${gauge.name} threads require a gauge serial number` }
            : omit(alerts, gauge.name))
        })
        return edit ?
        editThreadReport(edit.id, values, () => {
          enqueueSnackbar('Report updated', { variant: 'success'});
          actions.resetForm();
          onClose();
        })
        :
        createThreadReport(values, () => {
          enqueueSnackbar('Report added', { variant: 'success'});
          actions.resetForm();
          onClose();
        });
      }}
      validationSchema={
        object().shape({
          report: string().required(),
          date: string().required(),
          inspector: string().required(),
          items: mixed().test({
            name: 'items',
            test: value => size(value) !== 0,
            message: 'Please select at least one item'
          }),
          reports: array().min(1, "At least one report is required").required(),
        })
      }
    >
      {(formikProps) => {
      const {
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        resetForm
      } = formikProps;
      const handleClose = () => {
        const changed = !isEqual(values, edit);
        if (edit && changed) return confirm({
          description: 'You have unsaved changes, are you sure you want to close?'
        }).then(onClose);
        if (!edit) map(values.reports, ({path}) => deleteThreadReport(path));
        resetForm();
        return onClose();
      };
      return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Form onSubmit={handleSubmit}>
        <DialogContent>
          <Card className={classes.card} raised>
            <CardHeader
              title="Thread Inspection"
            />
            <CardContent>
              <Grid container spacing={3} className={classes.report}>
                <Grid item xs={12} sm={7}>
                  <TextField
                    name="report"
                    label="Report"
                    value={values.report}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.report && errors.report)}
                    helperText={touched.report && errors.report}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    name="date"
                    label="Date"
                    value={values.date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.date && errors.date)}
                    helperText={touched.date && errors.date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="inspector"
                    label="Inspector"
                    value={values.inspector}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    error={Boolean(touched.inspector && errors.inspector)}
                    helperText={touched.inspector && errors.inspector}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                  <Grid item xs={12}>
                    <Dropzone 
                      onDrop={files => {
                        files[0] && uploadThreadReport(jobId, files[0], ({ progress, error, url, name, path, fullPath}) => {
                          if (error) enqueueSnackbar(error, {variant: 'error'});
                          if (progress) setProgess(progress);
                          if (url) {
                            setProgess('');
                            if (values.reports) values.reports.push({ url, name, path, fullPath});
                            const reports = values.reports || [{ url, name, path, fullPath}];
                            setFieldValue('reports', reports);
                            edit && editThreadReport(edit.id, { ...edit, reports });
                          }
                        });
                        
                      }}
                      accept='application/pdf'
                      onDropRejected={() => enqueueSnackbar('Only single .pdf files are allowed', { variant: 'warning'})}
                      multiple={false}
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragAccept,
                        isDragReject,
                      }) => (
                        <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <TextField
                          className={clsx({
                            [classes.accept]: isDragAccept,
                            [classes.reject]: isDragReject
                          })}
                          label="Upload Thread Inspection Reports"
                          placeholder="Click or drop files here to upload..."
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={Boolean(touched.reports && errors.reports)}
                          helperText={touched.reports && errors.reports}
                        />
                        </div>
                      )}
                    </Dropzone>
                  </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Reports</Typography>
                  <List>
                    {map(values.reports, ({ url, name, path }, rIndex) => {
                      return (
                        <ListItem
                          key={url}
                          component="a"
                          button
                          href={url}
                          target="_blank"
                        >
                          {name}
                          <ListItemSecondaryAction>
                            <IconButton onClick={() => {
                              confirm({
                                description: 'Are you sure you want to delete this report file?'
                              })
                              .then(() => {
                                deleteThreadReport(path, err => {
                                  if (err) return enqueueSnackbar(err, { variant: 'error' });
                                  values.reports.splice(rIndex, 1);
                                  setFieldValue('reports', values.reports);
                                  edit && editThreadReport(edit.id, { ...edit, reports: values.reports });
                                  enqueueSnackbar('File deleted!', { variant: 'warning' });
                                });
                              });
                            }}>
                              <DeleteForever />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                    {progress &&  
                      <ListItem>
                        <div className={classes.progress}>
                          <LinearProgress variant="determinate" value={progress || 0} />
                        </div>
                      </ListItem>
                    }
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.card} raised>
            <CardContent>
              {map(values.gauges, ({name, callout, gauge}, index) => {
                return (
                      <Grid container spacing={2} key={name} className={classes.thread}>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            variant="outlined"
                            label="Thread"
                            value={name}
                            fullWidth
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <TextField
                            variant="outlined"
                            label="Profile"
                            value={callout}
                            fullWidth
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            variant="outlined"
                            label="Gauge"
                            value={values.gauges[index].gauge || ''}
                            onChange={e => {
                              values.gauges[index].gauge = e.target.value;
                              setFieldValue('gauges', [...values.gauges]);
                            }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                );
              })}
            </CardContent>
          </Card>
          <Card className={classes.card} raised>
            <CardContent>
              <List dense>
                {map(items, (item, itemId) => {
                  return (
                    <Fragment key={itemId}>
                      <ListItem>
                        <Checkbox
                          checked={
                            Boolean(
                              values.items &&
                              values.items[itemId] 
                              ) || false}
                          onChange={() => {
                            setFieldValue('items', 
                              values.items[itemId] ?
                              omit(values.items, itemId) || {}
                              : { ...values.items, [itemId]: item.profiles}
                              );
                          }}
                        />
                        <ListItemText
                          primary={item.asset}
                          secondary={item.description}
                        />
                        <Grid container spacing={0}>
                        {map(item.profiles, (profile, profileId) => {
                          return (
                              <Chip
                                label={profile.name}
                                key={profileId}
                                avatar={<Avatar>{profile.qty}</Avatar>}
                              />
                          )
                        })}
                          
                        </Grid>
                      </ListItem>
                    </Fragment>
                    );
                })}
              </List>
            </CardContent>
          </Card>
          {Boolean(touched.items && errors.items) &&
            <Alert severity="error">{errors.items}</Alert>
          }
          {Boolean(size(alerts) !== 0) &&
            map(alerts, (message, threadName) => (<Alert severity="error" key={threadName}>{message}</Alert>))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="primary">{edit ? 'Update' : 'Submit'}</Button>
        </DialogActions>
      </Form>
      </Dialog>
      )}}
    </Formik>
  );
};



export default connect(null, actions)(Rebuild);


