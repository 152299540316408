import { map } from "lodash";
import React from "react";
import {
  Typography,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: theme.spacing(3),
  },
}));

const Review = ({
  formikProps: {
    setFieldValue,
    values: {
      asset,
      description,
      visual,
      visualPics,
      utt,
      scanPlanId,
      scanPlan,
      mins,
      rebuild,
      hydro,
      wp,
      thread,
      profiles,
      mpi,
      fiveYear,
    },
  errors,
  }
}) => {
  const classes = useStyles();
  const handleChange = e => setFieldValue('fiveYear', e.target.checked)
  return (
    <div>
      <Typography variant="h4">Review</Typography>
      <Divider className={classes.divider} />
      <Typography variant="h6">{asset}</Typography>
      <Typography variant="body1">{description}</Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={visual} />}
          label="Visual Inspection"
        />
        <FormControlLabel
          control={<Checkbox checked={visualPics} />}
          label="Photos Required"
        />
        <FormControlLabel
          control={<Checkbox checked={utt} />}
          label="Ultrasonic Inspection"
        />
        <FormControlLabel
          control={<Checkbox checked={rebuild} />}
          label="Rebuild/Repack"
        />
        <FormControlLabel
          control={<Checkbox checked={hydro} />}
          label="Pressure Test"
        />
        <FormControlLabel
          control={<Checkbox checked={thread} />}
          label="Thread Inspection"
        />
        <FormControlLabel
          control={<Checkbox checked={mpi} />}
          label="Magnetic Particle Inspection"
        />
      </FormGroup>
      <Divider />
      <FormGroup style={{marginTop: 16}}>
        <FormControlLabel
          control={<Checkbox checked={fiveYear} onChange={handleChange}/>}
          label="5 Year COC"
        />
      </FormGroup>
      
      {map(errors, (error, field) => {
        return (
          <Alert severity="error" key={field}>
            {error}
          </Alert>
        );
      })}
    </div>
  );
};

export default Review;
