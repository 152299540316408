import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack'
import {
  TextField,
  IconButton,
  Grid
} from '@material-ui/core';
import {
  Create,
  SaveAlt,
  Add,
  DeleteForever
} from '@material-ui/icons';
//import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
// import { object, string } from 'yup';
import { useConfirm } from 'components/confirm';


import * as actions from 'actions/index.js';


// const useStyles = makeStyles(() => ({
  
// }));


const Settings = props => {
  const {
      fetchSettings,
      addThread,
      editThread,
      deleteThread,
      settings,
  } = props;
  // const classes = useStyles();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = React.useState({});
  React.useEffect(() => {
    const fetch = () => fetchSettings();
    fetch();
  }, [fetchSettings]);
  // if (!settings) return <div>Loading...</div>
  return (
    <Formik
      initialValues={{
        name: '',
        profile: ''
      }}
      onSubmit={(values, actions) => {
        addThread(values, () => {
          enqueueSnackbar('Thread added', { variant: 'success'});
          actions.resetForm();
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        
      }) => {
        return (
          <React.Fragment>
            <Form>
            <Grid container  justifyContent="center" alignItems="center" alignContent="center" spacing={1}>
              <Grid item xs={4}>
                <TextField
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  label="Thread Name"
                  variant="outlined"
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="profile"
                  value={values.profile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  label="Profile"
                  variant="outlined"
                  error={Boolean(touched.profile && errors.profile)}
                  helperText={touched.profile && errors.profile}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton type="submit">
                  <Add />
                </IconButton>
              </Grid>
            </Grid>
            </Form>
            {_.map(settings && settings.threads, (thread, threadId) => {
              return (
                <form
                  key={threadId}
                  onSubmit={e => {
                    e.preventDefault();
                    edit[threadId]
                    ? editThread(threadId, edit[threadId], () => {
                      enqueueSnackbar('Thread updated', { variant: 'success'});
                      setEdit(_.omit(edit, threadId));
                    })
                    : setEdit({ ...edit, [threadId]: thread});
                  }}
                >
                  <Grid container  justifyContent="center" alignItems="center" alignContent="center" spacing={1}>
                    <Grid item xs={4}>{
                      edit[threadId] ?
                      <TextField
                          value={edit[threadId].name}
                          onChange={e => setEdit({ ...edit, [threadId]: {...edit[threadId], name: e.target.value }})}
                          fullWidth
                          label="Thread Name"
                          variant="outlined"
                        />
                      :thread.name
                    }</Grid>
                    <Grid item xs={6}>{
                      edit[threadId] ?
                      <TextField
                          value={edit[threadId].profile}
                          onChange={e => setEdit({ ...edit, [threadId]: {...edit[threadId], profile: e.target.value }})}
                          fullWidth
                          label="Profile"
                          variant="outlined"
                        />
                      :thread.profile
                    }</Grid>
                    <Grid item xs={2}>
                      <IconButton type="submit">
                        {edit[threadId] ? <SaveAlt /> : <Create />}
                      </IconButton>
                      {edit[threadId] && 
                      <IconButton onClick={() => {
                        confirm()
                        .then(() => {
                          deleteThread(threadId, () => enqueueSnackbar('Thread deleted', { variant: 'warning' }));
                          })
                      }}>
                        <DeleteForever />
                      </IconButton>}
                    </Grid>
                  </Grid>
                </form>
              )
            })}
          </React.Fragment>
        )
      }}
    </Formik>
  );
};

function mapStateToProps({ settings }) {
  return {
    settings
  }
}

export default connect(mapStateToProps, actions)(Settings);



