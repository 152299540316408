import { FETCH_JOBS } from 'actions/jobActions';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_JOBS:
            return action.payload;
        default:
            return state;
    }
}