import _, { map, find } from "lodash";
import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSnackbar } from "notistack";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  Slide,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Box,
  List,
  ListItem,
  Paper,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Menu,
  Badge,
  Divider,
} from "@material-ui/core";
import { orange, green } from "@material-ui/core/colors";
import {
  Close,
  ErrorOutline,
  MoreVert,
  CheckCircleOutline,
  PriorityHigh,
} from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import CameraAlt from "@material-ui/icons/CameraAlt";
import { useConfirm } from "components/confirm";
import NewItem from "components/NewItem";
import UTT from "components/utt";
import Rebuild from "components/rebuild";
import Hydro from "components/hydro";
import MPI from "components/mpi";
import Thread from "components/thread";
import Certificate from "components/cert";
import AddPhotos from "components/addPhotos";
import MTRs from "components/mtr";
import FailItem from "components/failItem";
import JobCSV from "components/jobCsv";
import CnDMailMerge from "components/CnDMailMerge";
import fail from "images/fail.png";

import * as jobActions from "actions/jobActions";
import * as itemActions from "actions/itemActions";

const actions = {
  ...jobActions,
  ...itemActions,
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  waiting: {
    color: orange[900],
  },
  pass: {
    color: green[900],
  },
  fail: {
    backgroundImage: `linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.3)
  ), url(${fail})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
  },
  gridRoot: {
    textAlign: "center",
    fontSize: ".7em",
    position: "relative",
  },
  paper: {
    width: "100%",
  },
  more: {
    position: "absolute",
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
  xsHeader: {
    paddingRight: theme.spacing(3),
  },
  badge: {
    "& span": {
      fontSize: ".7em",
      //transform: 'scale(1) translate(50%, -55%)',
      minWidth: 18,
      height: 18,
    },
  },
  badgeMobile: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    "& span": {
      fontSize: ".7em",
      //transform: 'scale(1) translate(50%, -55%)',
      minWidth: 18,
      height: 18,
    },
  },
  making: {
    width: "100%",
    height: "90%",
    position: "absolute",
    top: 3,
    left: 0,
    backgroundColor: "rgba(240,240,240,0.82)",
  },
  makingSm: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: "rgba(240,240,240,0.82)",
  },
  working: {
    position: "absolute",
    fontWeight: "bold",
    fontSize: "1.2em",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  workingSm: {
    position: "absolute",
    fontWeight: "bold",
    fontSize: "1.2em",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  relative: {
    position: "relative",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Job = ({
  open,
  onClose,
  job,
  jobId,
  editItem,
  deleteItem,
  fetchJobItems,
  fetchUTTReports,
  fetchRBReports,
  fetchHydroReports,
  fetchMPIReports,
  fetchThreadReports,
  clearItems,
  utt,
  rebuild,
  hydro,
  items,
  mpi,
  thread,
  certsInProgress,
  addThumbnailUrl,
}) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  React.useEffect(() => {
    const fetchItems = () => {
      if (jobId) {
        fetchJobItems(jobId);
      }
    };
    fetchItems();
  }, [fetchJobItems, jobId]);
  React.useEffect(() => {
    const fetch = () => {
      if (jobId) {
        fetchUTTReports(jobId);
        fetchRBReports(jobId);
        fetchHydroReports(jobId);
        fetchMPIReports(jobId);
        fetchThreadReports(jobId);
      }
    };
    fetch();
  }, [
    fetchUTTReports,
    fetchRBReports,
    fetchHydroReports,
    fetchMPIReports,
    fetchThreadReports,
    jobId,
  ]);
  const { workOrder, customer, packageNumber } = job || {};
  const [openNew, setOpenNew] = useState(false);
  const [openUTT, setOpenUTT] = useState(false);
  const [openRebuild, setOpenRebuild] = useState(false);
  const [openHydro, setOpenHydro] = useState(false);
  const [openMPI, setOpenMPI] = useState(false);
  const [openThread, setOpenThread] = useState(false);
  const [pop, setPop] = useState(null);
  const [clone, setClone] = useState(null);
  const [edit, setEdit] = useState(null);
  const [fail, setFail] = useState(null);
  const [menu, setMenu] = useState(null);
  const [editUTT, setEditUTT] = useState(false);
  const [editRB, setEditRB] = useState(false);
  const [editHydro, setEditHydro] = useState(false);
  const [editMPI, setEditMPI] = useState(false);
  const [editThread, setEditThread] = useState(false);
  const [lineItem, setLineItem] = useState("");
  const [photos, setPhotos] = useState("");
  const [indicationPhotos, setIndicationPhotos] = useState("");
  const [mtrs, setMtrs] = useState(null);
  const [unfinished, setUnfinished] = useState(false);

  const checkForReportURL = (itemId, itemPhotos) => {
    return map(itemPhotos, ({ reportUrl, url }, picId) => {
      if (!url) return null;
      if (url && reportUrl) return null;
      if (url && !reportUrl) {
        addThumbnailUrl(itemId, picId, url);
      }
    });
  };
  React.useEffect(() => {
    if (lineItem) {
      openHydroReport(lineItem);
    }
  }, [hydro]);

  const hasUTT = (itemId) => {
    const evaluate = _(utt)
      .map("items")
      .map(itemId)
      .map((info) => Boolean(info && info.readings))
      .value();
    const result = _.includes(evaluate, true);
    return result;
  };

  const hasVisual = (itemId) => {
    const evaluate = _(utt)
      .map("items")
      .map(itemId)
      .map((info) => info && info.visual)
      .value();
    return _.includes(evaluate, true);
  };

  const hasVisualIndication = (itemId) => {
    const evaluate = _(utt)
      .map("items")
      .map(itemId)
      .map((info) => info && info.visualIndication)
      .value();
    return _.includes(evaluate, true);
  };

  const hasRb = (itemId) => {
    const evaluate = _(rebuild)
      .map("items")
      .map(itemId)
      .map((info) => Boolean(info))
      .value();
    return _.includes(evaluate, true);
  };

  const hasMPI = (itemId) => {
    const evaluate = _(mpi)
      .map("items")
      .map(itemId)
      .map((info) => Boolean(info))
      .value();
    return _.includes(evaluate, true);
  };

  const hasThread = (itemId) => {
    const evaluate = _(thread)
      .map("items")
      .map(itemId)
      .map((info) => Boolean(info)) //check qty here
      .value();
    return _.includes(evaluate, true);
  };

  const hasHydro = (itemId) => {
    const searchAsset = items && items[itemId] && items[itemId].asset;
    const evaluate = _(hydro)
      .map("items")
      .map((chartList) => {
        const first = find(chartList, (testChartItem, chartItemId) => {
          return chartItemId === itemId;
        });
        if (first) return first;
        const next = find(chartList, (testChartItem) => {
          return testChartItem && testChartItem.asset === searchAsset;
        });
        return next;
      })
      .map((info) => {
        return Boolean(
          (info && !info.associated) || (info && info.asset === searchAsset)
        );
      })
      .value();
    return _.includes(evaluate, true);
  };

  const openReport = (itemId) => {
    const evaluate = _(utt)
      .pickBy((report) => Boolean(report.items[itemId]))
      .map((result, id) => ({ ...result, id }))
      .value();
    setEditUTT(evaluate[0]);
    return setOpenUTT(true);
  };

  const openRbReport = (itemId) => {
    const evaluate = _(rebuild)
      .pickBy((report) => Boolean(report.items[itemId]))
      .map((result, id) => ({ ...result, id }))
      .value();
    setEditRB(evaluate[0]);
    return setOpenRebuild(true);
  };

  const openHydroReport = (itemId) => {
    const searchAsset = items && items[itemId] && items[itemId].asset;
    const evaluate = _(hydro)
      .pickBy((report) => {
        const isItemId = Boolean(
          report.items &&
            report.items[itemId] &&
            !report.items[itemId].associated
        );
        const hasAssetNumber = Boolean(
          find(report.items, (chartItem) => chartItem.asset === searchAsset)
        );
        return Boolean(isItemId || hasAssetNumber);
      })

      .map((result, id) => ({ ...result, id }))
      .value();
    setEditHydro(evaluate);
    setLineItem(itemId);
    return setOpenHydro(true);
  };

  const openMPIReport = (itemId) => {
    const evaluate = _(mpi)
      .pickBy((report) => Boolean(report.items[itemId]))
      .map((result, id) => ({ ...result, id }))
      .value();
    setEditMPI(evaluate[0]);
    return setOpenMPI(true);
  };

  const openThreadReport = (itemId) => {
    const evaluate = _(thread)
      .pickBy((report) => Boolean(report.items[itemId]))
      .map((result, id) => ({ ...result, id }))
      .value();
    setEditThread(evaluate[0]);
    return setOpenThread(true);
  };

  const visualItemsLeft = _.omit(
    _.pickBy(items, { visual: true }),
    _.map(items, (i, iId) => {
      return editUTT
        ? hasVisual(iId) && !editUTT.items[iId] && iId
        : hasVisual(iId) && iId;
    })
  );

  const uttItemsLeft = _.omit(
    _.pickBy(items, { utt: true }),
    _.map(items, (i, iId) => {
      return editUTT
        ? hasUTT(iId) && !editUTT.items[iId] && iId
        : hasUTT(iId) && iId;
    })
  );

  const rbItemsLeft = _.omit(
    _.pickBy(items, { rebuild: true }),
    _.map(items, (i, iId) => {
      return editRB
        ? hasRb(iId) && !editRB.items[iId] && iId
        : hasRb(iId) && iId;
    })
  );

  const hydroItemsLeft = _.omit(
    _.pickBy(items, { hydro: true }),
    _.map(items, (i, iId) => {
      return editHydro
        ? hasHydro(iId) && !_.includes(editHydro, iId) && iId
        : hasHydro(iId) && iId;
    })
  );

  const mpiItemsLeft = _.omit(
    _.pickBy(items, { mpi: true }),
    _.map(items, (i, iId) => {
      return editMPI
        ? hasMPI(iId) && !editMPI.items[iId] && iId
        : hasMPI(iId) && iId;
    })
  );

  const threadItemsLeft = _.omit(
    _.pickBy(items, { thread: true }),
    _.map(items, (i, iId) => {
      return editThread
        ? hasThread(iId) && !editThread.items[iId] && iId
        : hasThread(iId) && iId;
    })
  );

  const isComplete = (itemId, attr) => {
    switch (attr) {
      case "thread":
        return hasThread(itemId) ? (
          <CheckCircleOutline
            className={classes.pass}
            onClick={() => openThreadReport(itemId)}
          />
        ) : (
          <ErrorOutline
            className={classes.waiting}
            onClick={() =>setOpenThread(true)}
          />
        );
      case "mpi":
        return hasMPI(itemId) ? (
          <CheckCircleOutline
            className={classes.pass}
            onClick={() => openMPIReport(itemId)}
          />
        ) : (
          <ErrorOutline
            className={classes.waiting}
            onClick={() => setOpenMPI(true)}
          />
        );
      case "hydro":
        return hasHydro(itemId) ? (
          <CheckCircleOutline
            className={classes.pass}
            onClick={() => openHydroReport(itemId)}
          />
        ) : (
          <ErrorOutline
            className={classes.waiting}
            onClick={() => setOpenHydro(true)}
          />
        );
      case "rebuild":
        return hasRb(itemId) ? (
          <CheckCircleOutline
            className={classes.pass}
            onClick={() => openRbReport(itemId)}
          />
        ) : (
          <ErrorOutline
            className={classes.waiting}
            onClick={() => setOpenRebuild(true)}
          />
        );
      case "visual":
        return hasVisual(itemId) ? (
          <CheckCircleOutline
            className={classes.pass}
            onClick={() => openReport(itemId)}
          />
        ) : (
          <ErrorOutline
            className={classes.waiting}
            onClick={() => setOpenUTT(true)}
          />
        );
      case "utt":
        return hasUTT(itemId) ? (
          <CheckCircleOutline
            className={classes.pass}
            onClick={() => openReport(itemId)}
          />
        ) : (
          <ErrorOutline
            className={classes.waiting}
            onClick={() => setOpenUTT(true)}
          />
        );
      default:
        return <ErrorOutline className={classes.waiting} />;
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(menu)}
      onClose={() => setMenu(null)}
    >
      <MenuItem
        onClick={() => {
          setOpenNew(true);
          setMenu(null);
        }}
      >
        New Item
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          setOpenUTT(true);
          setMenu(null);
        }}
      >
        Enter UT Readings
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenRebuild(true);
          setMenu(null);
        }}
      >
        Enter Rebuild Info
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenHydro(true);
          setMenu(null);
        }}
      >
        Enter Hydrotest Info
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenMPI(true);
          setMenu(null);
        }}
      >
        Enter MPI Info
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenThread(true);
          setMenu(null);
        }}
      >
        Enter Thread Info
      </MenuItem>
      <Divider />
        <MenuItem style={{'&:hover' : {"& div": { backgroundColor: 'rgba(0, 0, 0, 0.04)'}} }}><JobCSV jobId={jobId}/></MenuItem>
      <MenuItem
        onClick={() => {
          setUnfinished((prev) => !prev);
          setMenu(null);
        }}
      >
        Filter Out Completed
      </MenuItem>
      <Divider />
      {/*<CnDMailMerge jobId={jobId}/>*/}
      <MenuItem>
        <Certificate jobId={jobId} customer={customer} packageNumber={packageNumber} setMenu={setMenu}/>
      </MenuItem>
      {job && job.zipFile && (
        <MenuItem component="a" href={job.zipFile}>
          Download Zip
        </MenuItem>
      )}
    </Menu>
  );

  const MoreMenu = ({ itemId, item }) => (
    <Menu
      open={Boolean(pop && pop.id === itemId)}
      variant="menu"
      anchorEl={pop && pop.t}
      autoFocus={false}
      disableAutoFocusItem
      onClose={() => setPop(null)}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MenuItem
        onClick={() => {
          setPop(null);
          setClone({ ...item, asset: "", cocs: null, mtrs: null, images: null, indicationPictures: null, complete: null });
          setOpenNew(true);
        }}
      >
        Clone Item
      </MenuItem>
      <MenuItem
        onClick={() => {
          setPop(null);
          setEdit({ ...item, id: itemId });
          setOpenNew(true);
        }}
      >
        Edit Item
      </MenuItem>
      <MenuItem
        onClick={() => {
          const itemValues = {
            ...item,
            complete: item.complete === jobId ? null : jobId,
          };
          setPop(null);
          editItem(itemId, itemValues, (f) => f);
        }}
      >
        Mark Complete
      </MenuItem>
      <MenuItem>
        <div onClick={() => {
        setPhotos(itemId);
          setPop(null);
        }}>Add Photos</div>
      </MenuItem>
      {hasVisualIndication(itemId) && (
        <MenuItem
          style={{
            backgroundColor:
              hasVisualIndication(itemId) && !item.indicationPictures
                ? `${orange[500]}66`
                : "inherit",
          }}
        >
          <div onClick={() => {
          setIndicationPhotos(itemId);
            setPop(null);
          }}>
            Add Indication Photos
          </div>
        </MenuItem>
      )}
      <MenuItem>
        <div
          onClick={() => {
            setMtrs(itemId);
            setPop(null);
          }}
        >
          Add MTRs
        </div>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setPop(null);
          confirm({ description: "Are you sure you want to delete?" })
            .then(() => {
              deleteItem(itemId, () => {
                enqueueSnackbar("Item deleted", { variant: "warning" });
              });
              setPop(null);
            })
            .catch(() => setPop(null));
        }}
      >
        Remove Item
      </MenuItem>
      <MenuItem>
        <div
          onClick={() => {
            setFail(itemId);
            setPop(null);
          }}
        >
          Fail Item
        </div>
      </MenuItem>
      <MenuItem>
        <Certificate jobId={jobId} customer={customer} setMenu={setPop} singleCOC={itemId} packageNumber={packageNumber}/>
      </MenuItem>
      {items[itemId] &&
        items[itemId].dataPackets &&
        items[itemId].dataPackets[jobId] && (
          <MenuItem
            component="a"
            target="_blank"
            rel="noreferrer noopener"
            href={items[itemId].dataPackets[jobId]}
          >
            Full Data Packet
          </MenuItem>
        )}
    </Menu>
  );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              clearItems();
              onClose();
            }}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {workOrder} - {customer}
          </Typography>
          <IconButton
            autoFocus
            color="inherit"
            onClick={(e) => setMenu(e.target)}
          >
            <MenuIcon />
          </IconButton>
          {renderMenu}
          {items && (
            <AddPhotos
              item={items[photos] || {}}
              itemId={photos}
              open={Boolean(photos)}
              onClose={() => setPhotos("")}
            />
          )}
          {items && (
            <AddPhotos
              item={items[indicationPhotos] || {}}
              itemId={indicationPhotos}
              open={Boolean(indicationPhotos)}
              onClose={() => setIndicationPhotos("")}
              indication
            />
          )}
          {items && (
            <MTRs
              item={items[mtrs]}
              itemId={mtrs}
              onClose={() => setMtrs(null)}
            />
          )}
          {items && (
            <FailItem
              open={Boolean(fail)}
              onClose={() => setFail(null)}
              itemId={fail}
            />
          )}
          <NewItem
            open={openNew}
            onClose={() => {
              setOpenNew(false);
              setEdit(null);
              setClone(null);
              setPop(null);
            }}
            customer={customer}
            jobId={jobId}
            clone={clone}
            edit={edit}
            workOrder={workOrder}
          />
          {openUTT && (
            <UTT
              open={openUTT}
              edit={editUTT}
              onClose={() => {
                setOpenUTT(false);
                setEditUTT(false);
              }}
              items={{ ...uttItemsLeft, ...visualItemsLeft }}
              jobId={jobId}
              workOrder={workOrder}
            />
          )}
          {openHydro && (
            <Hydro
              open={openHydro}
              editCharts={editHydro}
              onClose={() => {
                setOpenHydro(false);
                setEditHydro(false);
                setLineItem("");
              }}
              items={hydroItemsLeft}
              jobId={jobId}
              workOrder={workOrder}
              lineItem={lineItem}
            />
          )}
          {openMPI && (
            <MPI
              open={openMPI}
              edit={editMPI}
              onClose={() => {
                setOpenMPI(false);
                setEditMPI(false);
              }}
              items={mpiItemsLeft}
              jobId={jobId}
              workOrder={workOrder}
            />
          )}
          {openThread && (
            <Thread
              open={openThread}
              edit={editThread}
              onClose={() => {
                setOpenThread(false);
                setEditThread(false);
              }}
              items={threadItemsLeft}
              jobId={jobId}
              workOrder={workOrder}
            />
          )}
          <Rebuild
            open={openRebuild}
            edit={editRB}
            onClose={() => {
              setOpenRebuild(false);
              setEditRB(false);
            }}
            items={rbItemsLeft}
            jobId={jobId}
            workOrder={workOrder}
          />
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.dialogContent}>
        {items ? (
          <React.Fragment>
            <Box display={{ xs: "none", sm: "none", md: "block" }}>
              <TableContainer component={Paper}>
                <Table size="small" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Visual</TableCell>
                      <TableCell>Rebuild</TableCell>
                      <TableCell>UTT</TableCell>
                      <TableCell>Thread</TableCell>
                      <TableCell>Hydro</TableCell>
                      <TableCell>MPI</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(items, (item, itemId) => {
                      const makingCerts =
                        certsInProgress[itemId] ||
                        certsInProgress[itemId] === 0;
                      checkForReportURL(itemId, item.images);
                      if (unfinished && item.cocs && item.cocs[jobId])
                        return null;
                      return (
                        <TableRow
                          key={itemId}
                          className={clsx({
                            [classes.relative]: true,
                            [classes.fail]: !!item.fail,
                          })}
                        >
                          <TableCell>
                            {makingCerts ? (
                              <span className={classes.making}>
                                <Typography
                                  className={classes.working}
                                  variant="button"
                                >
                                  {typeof certsInProgress[itemId] === "number"
                                    ? `Uploading Certificate ${certsInProgress[
                                        itemId
                                      ].toFixed(0)}%`
                                    : typeof certsInProgress[itemId] ===
                                      "string"
                                    ? certsInProgress[itemId]
                                    : "Creating Certificate"}
                                </Typography>
                              </span>
                            ) : null}
                            {item.cocs && item.cocs[jobId] ? (
                              <a
                                href={item.cocs[jobId]}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                {item.asset}
                              </a>
                            ) : (
                              item.asset
                            )}
                          </TableCell>
                          <TableCell>
                            {item.description}
                            {item.complete ? (
                              <span style={{ color: green[500] }}>
                                {"\u2713"}
                              </span>
                            ) : null}
                            {_.size(item.images) > 0 && (
                              <Badge
                                className={classes.badge}
                                badgeContent={_.size(item.images)}
                                color="primary"
                                overlap="rectangular"
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => setPhotos(itemId)}
                                >
                                  <CameraAlt />
                                </IconButton>
                              </Badge>
                            )}
                          </TableCell>
                          {[
                            "visual",
                            "rebuild",
                            "utt",
                            "thread",
                            "hydro",
                            "mpi",
                          ].map((attr) => (
                            <TableCell key={attr}>
                              {item[attr] ? isComplete(itemId, attr) : null}
                            </TableCell>
                          ))}
                          <TableCell>
                            {matches ? (
                              <Fragment>
                                <IconButton
                                  onClick={(e) =>
                                    setPop({ t: e.currentTarget, id: itemId })
                                  }
                                  size="small"
                                >
                                  <Badge
                                    badgeContent={
                                      <PriorityHigh
                                        style={{ color: orange[500] }}
                                      />
                                    }
                                    invisible={
                                      Boolean(!hasVisualIndication(itemId) ||
                                      (hasVisualIndication(itemId) &&
                                        item.indicationPictures))
                                    }
                                    overlap="rectangular"
                                  >
                                    <MoreVert />
                                  </Badge>
                                </IconButton>
                                <MoreMenu item={item} itemId={itemId} />
                              </Fragment>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box display={{ sm: "block", md: "none" }}>
              <List dense>
                {map(items, (item, itemId) => {
                  checkForReportURL(itemId, item.images);
                  if (unfinished && item.cocs && item.cocs[jobId]) return null;
                  const Photos = () =>
                    _.size(item.images) > 0 && (
                      <Badge
                        className={classes.badgeMobile}
                        badgeContent={_.size(item.images)}
                        color="primary"
                        overlap="rectangular"
                      >
                        <IconButton
                          size="small"
                          onClick={() => setPhotos(itemId)}
                        >
                          <CameraAlt />
                        </IconButton>
                      </Badge>
                    );
                  return (
                    <ListItem key={itemId} button dense>
                      <Paper
                        elevation={1}
                        className={clsx({
                          [classes.paper]: true,
                          [classes.fail]: !!item.fail,
                        })}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          className={classes.gridRoot}
                          spacing={0}
                        >
                          <Grid item xs={12}>
                            {certsInProgress[itemId] ||
                            certsInProgress[itemId] === 0 ? (
                              <div className={classes.makingSm}>
                                <Typography
                                  className={classes.workingSm}
                                  variant="button"
                                >
                                  {typeof certsInProgress[itemId] === "number"
                                    ? `Uploading Certificate ${certsInProgress[
                                        itemId
                                      ].toFixed(0)}%`
                                    : "Creating Certificate"}
                                </Typography>
                              </div>
                            ) : null}
                            <Typography
                              variant="subtitle1"
                              className={classes.xsHeader}
                            >
                              {item.cocs && item.cocs[jobId] ? (
                                <a
                                  href={item.cocs[jobId]}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >{`${item.asset} - ${item.description}`}</a>
                              ) : (
                                `${item.asset} - ${item.description}`
                              )}
                              {item.complete ? (
                                <span style={{ color: green[500] }}>
                                  {"\u2713"}
                                </span>
                              ) : null}
                              <Photos />
                            </Typography>
                            {!matches ? (
                              <Fragment>
                                <IconButton
                                  size="small"
                                  className={classes.more}
                                  onClick={(e) =>
                                    setPop({ t: e.currentTarget, id: itemId })
                                  }
                                >
                                  <MoreVert />
                                </IconButton>
                                <MoreMenu item={item} itemId={itemId} />
                              </Fragment>
                            ) : null}
                          </Grid>
                          <Grid item xs={2}>
                            Visual
                          </Grid>
                          <Grid item xs={2}>
                            Rebuild
                          </Grid>
                          <Grid item xs={2}>
                            UTT
                          </Grid>
                          <Grid item xs={2}>
                            Thread
                          </Grid>
                          <Grid item xs={2}>
                            Hydro
                          </Grid>
                          <Grid item xs={2}>
                            MPI
                          </Grid>
                          {[
                            "visual",
                            "rebuild",
                            "utt",
                            "thread",
                            "hydro",
                            "mpi",
                          ].map((attr) => (
                            <Grid item xs={2} key={attr}>
                              {item[attr] ? isComplete(itemId, attr) : null}
                            </Grid>
                          ))}
                          <Grid item xs={12}></Grid>
                        </Grid>
                      </Paper>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </React.Fragment>
        ) : (
          <Card>
            <CardContent>
              <Typography variant="h6">No Items</Typography>
            </CardContent>
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
};

function mapStateToProps({
  items,
  utt,
  rebuild,
  hydro,
  mpi,
  thread,
  certsInProgress,
}) {
  return {
    items,
    utt,
    rebuild,
    hydro,
    mpi,
    thread,
    certsInProgress,
  };
}

export default connect(mapStateToProps, actions)(Job);
