import { map, values, size, range, includes, flatMap } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MobileStepper,
  Slide,
  ButtonGroup,
} from '@material-ui/core';
import { makeStyles,useTheme  } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Formik, Form } from 'formik';
import { object, string, number, boolean, mixed } from 'yup';

import * as actions from 'actions/itemActions.js';

import Add from 'components/items/add';
import Review from 'components/items/review';
import UploadItemList from 'components/uploadItemList';

const { Item, Visual, UTT, Rebuild, Hydro, Thread, MPI } = Add;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  step: {
    overflow: 'hidden',
    padding: theme.spacing(2),
    display: 'flex',
    minHeight: 600
  },
  actions: {
    display: 'block'
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    // position: 'absolute',
    // left: 0,
    // bottom: 52.5,
    // transform: 'translateX(50px)'
  },
  cloneList: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  content: {
    maxHeight: 'calc(100vh - 300px)'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const AddStep = React.forwardRef(({ step, formikProps }, ref) => {
  if (step === 0) return <div ref={ref}><Item formikProps={formikProps} /></div>
  if (step === 1) return <div ref={ref}><Visual formikProps={formikProps} /></div>
  if (step === 2) return <div ref={ref}><UTT formikProps={formikProps} /></div>
  if (step === 3) return <div ref={ref}><Rebuild formikProps={formikProps} /></div>
  if (step === 4) return <div ref={ref}><Hydro formikProps={formikProps} /></div>
  if (step === 5) return <div ref={ref}><Thread formikProps={formikProps} /></div>
  if (step === 6) return <div ref={ref}><MPI formikProps={formikProps} /></div>
  return <div ref={ref}><Review formikProps={formikProps}/></div>
})

const NewItem = props => {
  const {
    open,
    onClose,
    createItem,
    editItem,
    jobId,
    clone,
    edit,
    customer,
    workOrder,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [cloneList, setCloneList] = React.useState(false);
  const [slide, setSlide] = React.useState('left');
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSlide('left');
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setSlide('right');
  };
  React.useEffect(() => setActiveStep(0), [clone]);
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" className={classes.add}>
      <Formik
        initialValues={
        edit ? { ...edit, tempRating: edit.tempRating || '', fiveYear: edit.fiveYear || false }
        : clone ? { ...clone, tempRating: clone.tempRating || '', serial: '', fiveYear: clone.fiveYear || false  } 
        : {
          asset: '',
          description: '',
          oem: '',
          serial: '',
          tempRating: '',
          visual: true,
          visualPics: false,
          utt: true,
          scanPlanId: "",
          scanPlan: {},
          mins: [],
          rebuild: false,
          hydro: true,
          wp: 0,
          serviceType: '',
          tp: 0,
          fiveYear: false,
          intervals: [
            {
              holdType: 'low',
              target: [250],
              max: [300],
              duration: [300],
              name: 'Low',
            },{
              holdType: 'high',
              target: [0],
              max: [0],
              duration: [300],
              name: 'High',
            }
          ],
          thread: false,
          profiles: [
            { thread: '', qty: 1}
          ],
          mpi: false,
        }}
        validationSchema={
          object().shape({
            asset: string().required('An asset number is required')
            // .test({
            //   name: 'onTicket',
            //   test: value => {
            //     validateAssetNumber(value, jobId, result => {
            //     console.log(result)
            //       return result.onTicket;
            //     })
            //   },
            //   message: 'Item exists on current ticket'
            // }),
            ,
            description: string().required('An asset description is required'),
            utt: boolean(),
            scanPlanId: string().when('utt', {
              is: true,
              then: string().required('A scan plan is required')
            }),
            hydro: boolean(),
            wp: number().when('hydro', {
              is: true,
              then: number().moreThan(1, 'A working pressure is required')
            }),
            // profile: mixed().when('thread',{
            //   is: true,
            //   then: mixed().test(
            //     'has-profile',
            //     `A thread profile is empty`,
            //     (prof, context) => {
            //       console.log({prof, profFlat: flatMap(prof), result: includes(flatMap(prof), '') })
            //       return includes(flatMap(prof), '')
            //     }
            //   )
            // })
          })
        }
        onSubmit={(formValues, actions) => {
        const values = {...formValues, asset: formValues.asset.replace(/\s+$/, '')}
        const customValidate = () => {
          return actions.setFieldError('thread', 'A profile is required for thread check')
        }
        if ((values.thread && !values.profiles) || (values.thread && !values.profiles[0].thread)) {
          return customValidate()
        }
        const cb = () => {
          actions.resetForm();
          onClose();
        };
        !edit ? 
          createItem({ ...values, jobId, customer, workOrder}, cb)
        :
          editItem(edit.id, { ...values, workOrder}, cb);
        }}
      >
        {(formikProps) => (
          <Form>
            <DialogTitle>
              {edit ? `Update Ticket Item: ${edit.asset} ${edit.oem ? `(${edit.oem})`: ''}` : 'Add Item to Ticket'}
            </DialogTitle>
            <DialogContent className={classes.content}>
              {clone && <Button onClick={() => setCloneList(true)} color="primary" className={classes.cloneList}>Clone a list of items</Button>}
              <UploadItemList open={cloneList} onClose={() => setCloneList(false)} clone={clone}/>
              <div className={classes.step}>
                {activeStep !== size(Add) ?
                  map(values(Add), (step, index) => {
                    const show = index === activeStep;
                    return (
                      <Slide in={show} direction={slide} key={index.toString()}>
                        {show ? <AddStep step={activeStep} formikProps={{ ...formikProps, jobId}} /> : <div />}
                      </Slide>
                    );
                  })
                :
                  <Slide in={activeStep === size(Add)} direction={slide}>
                    {activeStep === size(Add) ? <AddStep step={activeStep} formikProps={formikProps} /> : <div />}
                  </Slide>
                }
              </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <MobileStepper
                variant="progress"
                steps={size(Add)+1}
                position="static"
                activeStep={activeStep}
                className={classes.root}
                nextButton={
                  <Button size="small" onClick={handleNext} disabled={activeStep === size(Add)}>
                    Next
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    Back
                  </Button>
                }
              />
              <div className={classes.group}>
                <ButtonGroup variant="text" color="primary">
                  {map(range(0,size(Add)+1, 1), step => (
                    <Button
                      size="small"
                      disabled={activeStep !== step ? false : true}
                      key={step.toString()}
                      onClick={() => setActiveStep(step)}
                    >{step + 1}</Button>
                  ))}
                </ButtonGroup>
              </div>
              <div className={classes.buttons}>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit" color="primary">{edit ? 'Update' : 'Add'} Item</Button>
              </div>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};



export default connect(null, actions)(NewItem);


