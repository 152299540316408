import { map } from 'lodash';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';

import { database } from 'firebase_config';
import { linkChart } from 'actions/itemActions';


const useStyles = makeStyles(theme => ({
  input: {
    marginTop: theme.spacing(2)
  },
  link: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(7)
  }
}))
const LinkHydro = ({
  jobId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const [open, setOpen] = useState(false);
  const [chart, setChart] = useState('DC');
  const [items, setItems] = useState({});
  const onClose = () => {
    setChart('DC');
    setItems({});
    setOpen(false);
  }
  const searchHydroCharts = (chart, cb) => {
      const test = database.ref(`hydrotest/tests/${chart}`)
      test.child('items').once('value', snapshot => {
        const chartItems = snapshot.val();
        return setItems(chartItems)
      })
  }
  useEffect(() => {
    return chart.length > 6 
    ? searchHydroCharts(chart)
    : setItems({})
  }, [chart]);
  return (
    <Fragment>
      <IconButton
        color="primary"
        className={classes.link}
        onClick={
          () => setOpen(true)
        }
      >
        <LinkIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          <Typography
            variant="body2"
          >
            Enter the chart number to link
          </Typography>
          <TextField
            value={chart}
            onChange={e => setChart(e.target.value)}
            variant="outlined"
            label="Chart Number"
            fullWidth
            className={classes.input}
          />
          <List>
            {map(items, (item, itemId) => (
              <ListItem key={itemId}>
                <ListItemText
                  primary= {item.asset}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => dispatch(linkChart(jobId, chart, onClose))}
          >
            Link Chart
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
    );
}

export default LinkHydro;
