import { omit } from 'lodash';
import { START_MAKING_CERT, STOP_MAKING_CERT, UPLOADING_CERT, ERROR_MAKING_CERT } from 'actions/itemActions';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case START_MAKING_CERT:
            return { ...state, [action.itemId]: true };
        case STOP_MAKING_CERT:
            return omit(state, action.itemId);
        case UPLOADING_CERT:
            return { ...state, [action.itemId]: action.progress };
        case ERROR_MAKING_CERT:
            return { ...state, [action.itemId]: 'ERROR: Certificate not created' };
        default:
            return state;
    }
}