import { map, includes, omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Divider,
  IconButton,
  LinearProgress
} from '@material-ui/core';
import { Add, DeleteForever } from '@material-ui/icons';
// import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import { useSnackbar } from 'notistack';

import { uploadPartDoc, deleteRBReport } from 'actions/itemActions';


// const useStyles = makeStyles(theme => ({
  
// }));


const Parts = props => {
  const {
    open,
    onClose,
    itemId,
    asset,
    setFieldValue,
    values,
    uploadPartDoc,
    deleteRBReport,
    jobId,
  } = props;
  // const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    part: '',
    traceNo: '',
    url: ''
  };
  const [vals, setVals] = React.useState(initialValues);
  const [errors, setErrors] = React.useState(initialValues);
  const [touched, setTouched] = React.useState(initialValues);
  const [progress, setProgess] = React.useState('');
  const handleClose = () => {
    setVals(initialValues);
    setTouched(initialValues);
    onClose();
  };
  const validate = () => {
    setErrors({
      part: vals.part ? '' : 'Part is required',
      traceNo: vals.traceNo ? '' : 'A traceability number is required',
      url: vals.url ? '' : 'A traceability doc is required',
    });
  };
  const canSubmit = () => {
    const hasNoError = !includes(map(errors, error => Boolean(error)), true);
    if (!hasNoError) {
      map(vals, (val, name) =>  setTouched({ ...touched, [name]: true}));
      validate();
    };
    return hasNoError;
  };
  
  const handleChange = e => {
    setVals({ ...vals, [e.target.name]: e.target.value });
  };
  
  const handleBlur = e => {
    setTouched({ ...touched, [e.target.name]: true});
  };
  
  React.useEffect(validate, [vals]);
  
  const handleSubmit = e => {
    e.preventDefault();
    if (canSubmit()) {
      //values.items[itemId].push(vals);
      setFieldValue('parts', {
        ...values.parts,
        [itemId]: { ...values.parts[itemId], [uuidv4().replace(/-/g, '')]: vals}
      });
      setVals(initialValues);
      setTouched(initialValues);
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Parts Changed for {asset}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={3}>
            {map(values.parts[itemId], (item, index) => {
              return (
                <Grid container item xs={12} spacing={0} key={index.toString()} alignContent="center">
                  <Grid item xs={4}>
                    {item.part}
                  </Grid>
                  <Grid item xs={4}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">{item.traceNo}</a>
                  </Grid>
                  <Grid item xs={4}>
                    <IconButton 
                        onClick={() => {
                          deleteRBReport(item.path, e => {
                            enqueueSnackbar(e ? e.message : 'Part removed', { variant: e ? 'error' : 'warning'});
                          });
                          setFieldValue('parts', { ...values.parts, [itemId]: omit(values.parts[itemId], index) });
                        }}
                      >
                      <DeleteForever/>
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}><Divider /></Grid>
              </Grid>
              );
            })}
            <Grid item xs={4}>
              <TextField
                name="part"
                value={vals.part}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                label="Part replaced"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(touched.part && errors.part)}
                helperText={touched.part && errors.part}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="traceNo"
                value={vals.traceNo}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                label="Traceability Number"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(touched.traceNo && errors.traceNo)}
                helperText={touched.traceNo && errors.traceNo}
              />
            </Grid>
            <Grid item xs={3}>
            <Dropzone 
                  onDrop={files => {
                    const args ={
                      jobId,
                      itemId
                    };
                    files[0] && uploadPartDoc(args, files[0], ({ progress, error, url, path, fullPath}) => {
                      if (error) enqueueSnackbar(error, {variant: 'error'});
                      if (progress) setProgess(progress);
                      if (url) {
                        setProgess('');
                        setVals({ ...vals, url, path, fullPath });
                      }
                    });
                    
                  }}
                  accept='application/pdf'
                  onDropRejected={() => enqueueSnackbar('Only single .pdf files are allowed', { variant: 'warning'})}
                  multiple={false}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  }) => (
                    <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {progress ? <LinearProgress variant="determinate" value={progress} /> : 
                    <TextField
                      value={vals.url || '' }
                      name="url"
                      onChange={e => setVals({ ...vals, url: e.target.value })}
                      onBlur={handleBlur}
                      variant="outlined"
                      label="Document"
                      placeholder="Drop here..."
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      error={Boolean(touched.url && errors.url)}
                      helperText={touched.url && errors.url}
                    />}
                    </div>
                  )}
                </Dropzone>
            </Grid>
            <Grid item xs={1}>
              <IconButton type="submit">
                <Add />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          color="primary"
          type="submit"
          onClick={e => {
            handleSubmit(e);
            handleClose();
          }}
        >Save and Close</Button>
      </DialogActions>
      </form>
    </Dialog>
  );
};





export default connect(null, { uploadPartDoc, deleteRBReport })(Parts);
