import { omit, find, mapValues, filter, size, pickBy } from 'lodash';

import uuid from 'uuid/v4';
import { database, storage } from 'firebase_config';
import { saveAs } from 'file-saver';

export const FETCH_JOBS = 'FETCH_JOBS';
export const FETCH_JOB_ITEMS = 'FETCH_JOB_ITEMS';
export const FETCH_SCAN_PLANS = 'FETCH_SCAN_PLANS';
export const FETCH_UTT_REPORTS = 'FETCH_UTT_REPORTS';
export const FETCH_RB_REPORTS = 'FETCH_RB_REPORTS';
export const FETCH_HYDRO_REPORTS = 'FETCH_HYDRO_REPORTS';
export const FETCH_THREAD_REPORTS = 'FETCH_THREAD_REPORTS';
export const FETCH_MPI_REPORTS = 'FETCH_MPI_REPORTS';
export const START_MAKING_CERT = 'START_MAKING_CERT';
export const STOP_MAKING_CERT = 'STOP_MAKING_CERT';
export const UPLOADING_CERT = 'UPLOADING_CERT';
export const ERROR_MAKING_CERT = 'ERROR_MAKING_CERT';

const jobs = database.ref('repair_work/jobs');
const items = database.ref('repair_work/items');
const utt = database.ref('repair_work/utt');
const mpi = database.ref('repair_work/mpi');
const rb = database.ref('repair_work/rb');
const hydro = database.ref('repair_work/hydro');
const thread = database.ref('repair_work/thread');
const scans = database.ref('utt/scans');

const uttReports = storage.ref('repair_work/utt/reports');
const mpiReports = storage.ref('repair_work/mpi/reports');
const rbReports = storage.ref('repair_work/rebuild/reports');
const hydroReports = storage.ref('repair_work/hydro/reports');
const threadReports = storage.ref('repair_work/thread/reports');
const cocReports = storage.ref('repair_work/cocs');
const images = storage.ref('repair_work/images');
const indications = storage.ref('repair_work/indicationPictures');
const mtrs = storage.ref('repair_work/mtrs');

//const rebuildForms = database.ref('rebuild/forms');

export function fetchJobItems(jobId) {
  return dispatch => items.orderByChild('jobId').equalTo(jobId).on('value', snapshot => {
    dispatch({
      type: FETCH_JOB_ITEMS,
      payload: snapshot ? snapshot.val() : {}
    });
  });
}

export function cancelJobItemFetch(jobId) {
  return () => items.orderByChild('jobId').equalTo(jobId).off('value')
}


export function cancelFetchReports(jobId) {
  return () => {
    utt.orderByChild('jobId').equalTo(jobId).off('value');
    mpi.orderByChild('jobId').equalTo(jobId).off('value');
    hydro.orderByChild('jobId').equalTo(jobId).off('value');
    thread.orderByChild('jobId').equalTo(jobId).off('value');
    rb.orderByChild('jobId').equalTo(jobId).off('value');
}}


export function clearItems() {
  return dispatch => {
    dispatch({
      type: FETCH_JOB_ITEMS,
      payload: {}
    })
    dispatch({
      type: FETCH_UTT_REPORTS,
      payload: {}
    })
    dispatch({
      type: FETCH_RB_REPORTS,
      payload: {}
    })
    dispatch({
      type: FETCH_HYDRO_REPORTS,
      payload: {}
    })
    dispatch({
      type: FETCH_THREAD_REPORTS,
      payload: {}
    })
    dispatch({
      type: FETCH_MPI_REPORTS,
      payload: {}
    })
}}

export function validateAssetNumber(value, jobId, cb) {
  return () => value && items.orderByChild('asset').equalTo(value).once('value', snapshot => {
    const found = snapshot.val();
    const is = find(found, { jobId })
    // console.log(found, is)
    found
    ? is
      ? cb({onTicket: true})
      : cb({existing: true})
    : cb({ valid: true})
  })
}

export function createItem(values, cb) {
  return () => items.push(values).then(cb);
}

export function cloneList(list, clone, cb) {
  return dispatch => list.split('\n').forEach(row => {
    const item = row.split('\t');
    const asset = item[0];
    const description = item[1];
    const oem = item[2];
    const serial = item[3];
    dispatch(createItem({ ...clone, asset, description, oem, serial}, cb))
  })
}
export function editItem(id, values, cb) {
  return () => items.child(id).set(values).then(cb);
}

export function deleteItem(id, cb) {
  return () => items.child(id).remove().then(cb);
}

export function fetchScanPlans() {
  return dispatch => scans.on('value', snapshot => {
    dispatch({
      type: FETCH_SCAN_PLANS,
      payload: snapshot ? snapshot.val() : {}
    });
  });
}

/*
================================
UTT Reports
================================
*/

export function createUTTReport(values, cb) {
  return () => utt.push(values).then(cb);
}
export function editUTTReport(id, values, cb) {
  return () => utt.child(id).set(values).then(cb);
}

export function fetchUTTReports(jobId) {
  return dispatch => utt.orderByChild('jobId').equalTo(jobId).on('value', snapshot => {
    const uttReports = snapshot && snapshot.val();
    const NoMissingItems = uttReports && pickBy(uttReports, report => Boolean(report && report.items))
    dispatch({
      type: FETCH_UTT_REPORTS,
      payload: NoMissingItems
    });
  });
}

export function addScanPlan(values, cb) {
  const file = values.file[0];
  return () => {
    const key = database.ref('utt/scans').push().key;
    const uploadTask = storage.ref('utt/scans_images').child(`${key}/${file.name}`).put(file);
    uploadTask.on('state_changed', snapshot => {
    // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // cb({progress});
    },
    error => {
      cb({ error });
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then(url => {
        database
        .ref('utt/scans')
        .child(key)
        .set({
          ...omit(values, 'file'),
          url,
          contentType: file.type,
          name: file.name
        })
        cb({ success: 'Scan plan added' });
      });
    })
  }
}

export function uploadUTTReport(jobId, file, cb) {
  const path = `${jobId}/${uuid()}/${file.name}`;
  return () => {
    const uploadTask = uttReports.child(path).put(file);
    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      cb({ progress });
    },
    error => {
      cb({ error });
    },
    async () => {
      const fullPath = await uploadTask.snapshot.ref.getMetadata().then(meta => meta.fullPath)
      uploadTask.snapshot.ref.getDownloadURL().then(url => {
        cb({ url, name: file.name, path, fullPath });
      });
    });
}}

export function deleteUTTReport(path, cb) {
  return () => uttReports.child(path).delete()
    .then(cb)
    .catch(err => cb(err));
}

/*
================================
MPI  Reports
================================
*/

export function createMPIReport(values, cb) {
  return () => mpi.push(values).then(cb);
}
export function editMPIReport(id, values, cb) {
  return () => mpi.child(id).set(values).then(cb);
}

export function fetchMPIReports(jobId) {
  return dispatch => mpi.orderByChild('jobId').equalTo(jobId).on('value', snapshot => {
    dispatch({
      type: FETCH_MPI_REPORTS,
      payload: snapshot && snapshot.val()
    });
  });
}


export function uploadMPIReport(jobId, file, cb) {
  const path = `${jobId}/${uuid()}/${file.name}`;
  return () => {
    const uploadTask = mpiReports.child(path).put(file);
    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      cb({ progress });
    },
    error => {
      cb({ error });
    },
    async () => {
      const fullPath = await uploadTask.snapshot.ref.getMetadata().then((metadata) => metadata.fullPath);
      uploadTask.snapshot.ref.getDownloadURL().then(url => {
        cb({ url, name: file.name, path, fullPath });
      });
    });
}}

export function deleteMPIReport(path, cb) {
  return () => mpiReports.child(path).delete()
    .then(cb)
    .catch(err => cb(err));
}

/*
================================
Hydro Reports
================================
*/

export function createHydroReport(values, cb) {
  return () => hydro.child(`${values.jobId}`).push(values).then(cb);
}
export function editHydroReport(id, values, cb) {
  const { replace } = values;
  const updateValues = {...values, replace: null, report: replace ? null : values.report}
  return () => hydro.child(`${values.jobId}`).child(id).update(updateValues).then(cb);
}

export function fetchHydroReports(jobId) {
  return dispatch => hydro.child(`${jobId}`).on('value', snapshot => {
    dispatch({
      type: FETCH_HYDRO_REPORTS,
      payload: snapshot && snapshot.val()
    });
  });
}


export function uploadHydroReport(jobId, file, cb) {
  const path = `${jobId}/${uuid()}/${file.name}`;
  return () => {
    const uploadTask = hydroReports.child(path).put(file);
    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      cb({ progress });
    },
    error => {
      cb({ error });
    },
    async () => {
      const fullPath = await uploadTask.snapshot.ref.getMetadata().then((metadata) => metadata.fullPath);
      uploadTask.snapshot.ref.getDownloadURL().then(url => {
        cb({ url, name: file.name, path, fullPath });
      });
    });
}}

export function deleteHydroReport(path, cb) {
  return () => hydroReports.child(path).delete()
    .then(cb)
    .catch(err => cb(err));
}

export function linkChart(jobId, chart, cb) {
  console.log(jobId)
  return () => database.ref(`hydrotest/tests/${chart}`).once('value', async snapshot => {
    const foundTest = await snapshot.val();
    const jobItems = await items.orderByChild('jobId').equalTo(jobId).once('value').then((s) => s.val());
    const includeOnReport = mapValues(jobItems, item => {
      const jobAssetNo = item.asset;
      console.log({jobAssetNo});
      
      const hydroItems = size(filter(foundTest.items, { asset: jobAssetNo }))>0;
      console.log({hydroItems});
      return Boolean(hydroItems);
    });
    console.log({includeOnReport})
    return foundTest && hydro.child(`${jobId}/${chart}`).set({ ...foundTest, chart, includeOnReport }).then(cb)
  })
}


export function includeChartonReport(jobId, chart, itemId) {
  return () => {
    const path = `repair_work/hydro/${jobId}/${chart}/includeOnReport/${itemId}`
    database.ref(path).transaction(itemId => itemId = !itemId)
  }
}

/*
================================
Thread Reports
================================
*/

export function createThreadReport(values, cb) {
  return () => thread.push(values).then(cb);
}
export function editThreadReport(id, values, cb) {
  return () => thread.child(id).set(values).then(cb);
}

export function fetchThreadReports(jobId) {
  return dispatch => thread.orderByChild('jobId').equalTo(jobId).on('value', snapshot => {
    dispatch({
      type: FETCH_THREAD_REPORTS,
      payload: snapshot && snapshot.val()
    });
  });
}


export function uploadThreadReport(jobId, file, cb) {
  const path = `${jobId}/${uuid()}/${file.name}`;
  return () => {
    const uploadTask = threadReports.child(path).put(file);
    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      cb({ progress });
    },
    error => {
      cb({ error });
    },
    async () => {
      const fullPath = await uploadTask.snapshot.ref.getMetadata().then((metadata) => metadata.fullPath);
      uploadTask.snapshot.ref.getDownloadURL().then(url => {
        cb({ url, name: file.name, path, fullPath });
      });
    });
}}

export function deleteThreadReport(path, cb) {
  return () => threadReports.child(path).delete()
    .then(cb)
    .catch(err => cb(err));
}

/*
================================
Rebuild Reports
================================
*/

export function fetchRBReports(jobId) {
  return dispatch => rb.orderByChild('jobId').equalTo(jobId).on('value', snapshot => {
    dispatch({
      type: FETCH_RB_REPORTS,
      payload: snapshot && snapshot.val()
    });
  });
}

export function createRBReport(values, cb) {
  return () => rb.push(values).then(cb);
}
export function editRBReport(id, values, cb) {
  return () => rb.child(id).set(values).then(cb);
}


export function uploadRBReport(jobId, file, cb) {
  const path = `${jobId}/${uuid()}/${file.name}`;
  return () => {
    const uploadTask = rbReports.child(path).put(file);
    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      cb({ progress });
    },
    error => {
      cb({ error });
    },
    async () => {
      const fullPath = await uploadTask.snapshot.ref.getMetadata().then((metadata) => metadata.fullPath);
      uploadTask.snapshot.ref.getDownloadURL().then(url => {
        cb({ url, name: file.name, path, fullPath });
      });
    });
}}

export function uploadPartDoc(args, file, cb) {
  const path = `${args.jobId}/${args.itemId}/${file.name}`;
  return () => {
    const uploadTask = rbReports.child(path).put(file);
    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      cb({ progress });
    },
    error => {
      cb({ error });
    },
    async () => {
      const fullPath = await uploadTask.snapshot.ref.getMetadata().then((metadata) => metadata.fullPath);
      uploadTask.snapshot.ref.getDownloadURL().then(url => {
        cb({ url, name: file.name, path, fullPath });
      });
    });
}}

export function deleteRBReport(path, cb) {
  return () => rbReports.child(path).delete()
    .then(cb)
    .catch(err => cb(err));
}

/*
================================
    Item Images
================================
*/

export function addItemPhoto(itemId, imageFile, cb, indication) {
  const itemPath = indication
  ? items.child(`${itemId}/indicationPictures`)
  : items.child(`${itemId}/images`);
  const newKey = itemPath.push().key;
  const key = `${itemId}*${newKey}`;
  const folder = indication ? indications : images
  const filePath = `${key}/${imageFile.name}`;
  return () => {
    const uploadTask = folder.child(filePath).put(imageFile);
    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      cb({ progress });
    },
    error => {
      cb({ error });
      database.ref("errors/logs").push({ error, timestamp: Date.now() })
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then(url => {
        itemPath.child(key).set({
          url,
          useOnReport: true
        })
        .then(() =>cb({ url }))
        .catch(error => {
      cb({ error });
      database.ref("errors/logs").push({ error, timestamp: Date.now() })
    } )
      });
    });

  }
}

export function addThumbnailUrl(itemId, picId, picUrl) {
  return () => {
    const urlObj = new URL(picUrl);
    const path = urlObj.pathname;
    const remove = '/v0/b/custom-app-project-test.appspot.com/o/repair_work/images'
    const removeLength = remove.length + 2;
    const filePath = path.substr(removeLength, path.length).replace(/%2F/gi, "/");
    const noExtension = filePath.slice(0, filePath.length - 4)
    const extension = filePath.slice(filePath.length - 4)
    const thumbnail = `${noExtension}_200x200${extension}`
    images.child(thumbnail).getDownloadURL()
      .then((reportUrl) => {
        console.log({reportUrl});
        items.child(`${itemId}/images/${picId}`).update({reportUrl})
      })
      .catch((metaerror) => {
        console.error({metaerror})
      });
  }
                
}
export function removeFromCert(itemId, imageId, indication) {
  return () => items.child(`${itemId}/${indication ? 'indicationPictures' : 'images'}/${imageId}/useOnReport`).transaction(add => !add)
}

/*
================================
    Upload MTRs
================================
*/

export function uploadMTR (itemId, mtrFile, cb) {
  const itemPath = items.child(`${itemId}/mtrs`);
  const newKey = itemPath.push().key;
  const key = `${itemId}*${newKey}`;
  const filePath = `${key}/${mtrFile.name}`;
  return () => {
    const uploadTask = mtrs.child(filePath).put(mtrFile);
    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      cb({ progress });
    },
    error => {
      cb({ error });
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then(url => {
        itemPath.child(key).set({
          url,
          filePath,
          name: mtrFile.name,
          useOnReport: true
        })
        .then(() =>cb({ url }));
      });
    });

  }
}

export function deleteMtr(itemId, mtrId, filePath) {
  return () => {
  const mtrPath = items.child(`${itemId}/mtrs/${mtrId}`);
  mtrPath.remove().then(() => {
    mtrs.child(filePath).delete()
  })
  }
}
/*
================================
    COC Reports
================================
*/

export function uploadCOC(jobId, itemId, blob, fileName, date, cb) {
  const path = `${jobId}/${fileName}`
  return dispatch => {
    const uploadTask = cocReports.child(path).put(blob)
    uploadTask.on('state_changed', snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      if (progress === 100) return dispatch(makingCert('stop', itemId));
      return dispatch(makingCert('uploading', itemId, progress));
    },
    error => {
      console.log({ error });
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then(url => {
        console.log(itemId)
        const updateValues = {
          [`/cocs/${jobId}`]: url,
          [`/cocDates/${jobId}`]: date
        };
        items.child(itemId).update(updateValues).then(cb);
      });
    });
  };
}

export function removeCOCReport(jobId, itemId, cb) {
  return () => {
    const hasCOCReport = items.child(`${itemId}/cocs/${jobId}`);
    hasCOCReport.once('value').then(async snap => {
      const coc = await snap.val();
      if (coc) {
        const url = new URL(coc);
        const path = url.pathname;
        const remove = '/v0/b/custom-app-project-test.appspot.com/o/repair_work%2Fcocs%2F'
        const removeLength = remove.length;
        const filePath = path.substr(removeLength, path.length).replace(/%2F/gi, "/");
        hasCOCReport.remove().then(cb());
        return cocReports.child(filePath).delete();
        };
      })
    // .remove().then(res => {
    //   cb();
    //   console.log({itemId, res})
    // });
  }
}

export function makingCert(command, itemId, progress) {
  return dispatch => {
    switch (command) {
      case 'start':
        dispatch({
          type: START_MAKING_CERT,
          itemId
        })
        break;
      case 'stop':
        dispatch({
          type: STOP_MAKING_CERT,
          itemId
        })
        break;
      case 'uploading':
        dispatch({
          type: UPLOADING_CERT,
          itemId,
          progress
        })
        break;
      case 'error':
        dispatch({
          type: ERROR_MAKING_CERT,
          itemId
        })
        break;
      default: 
        break;
    }
  }
}
export function markComplete(jobId, cb) {
  return () => jobs.child(jobId).update({ cocUpdated: Date.now()}) //.then(() => console.log('report coc updated'))
}

export function listFiles(jobId) {
  return () => cocReports.child(jobId).listAll().then(files => {
    files.items.forEach(file => file.getDownloadURL().then(url => saveAs(url)))
  })
}

export function updateAttachments(itemId, jobId, attachments) {
  return () => items.child(`${itemId}/attachments`).update({ [jobId]: attachments })
}

export function removeCocZip(jobId) {
  return () => jobs.child(jobId).child('zipFile').remove()
}