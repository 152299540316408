import { map, size, has } from 'lodash';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Fab,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon
} from '@material-ui/core';
import { Add, MoreVert, SlowMotionVideo, OfflinePin } from '@material-ui/icons';
import { lightBlue, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { useConfirm } from 'components/confirm';
import NewJob from 'components/newJob';
import Job from 'components/job';

import * as jobActions from 'actions/jobActions';
import { cancelJobItemFetch, cancelFetchReports } from 'actions/itemActions';
import * as indexActions from 'actions';

const actions = {
  ...jobActions,
  ...indexActions,
  cancelJobItemFetch,
  cancelFetchReports
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1)
  },
  list: {
    minHeight: 80,
    maxHeight: 'calc(95vh - 158px)',
    overflow: 'auto'
  },
  lastRow: {
    height: 62
  }
}));

const Jobs = ({
  jobs,
  filteredJobs,
  fetchJobs,
  fetchSettings,
  closeJob,
  customer,
  bugLog,
  cancelJobItemFetch,
  cancelFetchReports
}) => {
  const classes = useStyles();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState('');
  const [job, setJob] = useState('');
  const [menu, setMenu] = useState(null);
  React.useEffect(() => {
    const fetch = () => {
      fetchJobs(customer);
      fetchSettings();
    };
    fetch();
  }, [fetchJobs, customer, fetchSettings]);
  const renderMenu = (jobId) => {
    const { status } = jobs[jobId];
    return (
    <Menu
      anchorEl={menu && menu.t}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(menu && menu.id === jobId)}
      onClose={() => setMenu(null)}
    >
      <MenuItem onClick={() => {
        setEdit(jobId);
        setOpen(true);
        setMenu(null);
      }}>Edit Job</MenuItem>
      <MenuItem onClick={() => {
        setMenu(null);
        confirm({
          title: status === 'open' ? 'Close Job' : 'Reopen Job?',
          description:  status === 'open' &&
            'Are you sure you want to close this job? Closing the job will remove it from'
            +' this list and add it to the list of completed jobs on the customer page',
        })
        .then(() => closeJob(jobId, (err) => {
          err.committed 
          ? enqueueSnackbar(status === 'open' ? 'Job closed' : 'Job reopened' , { variant: 'success' })
          : enqueueSnackbar('An error occured, please try again', { variant: 'error' })
        }))
        .catch(() => (null))
      }}>{status === 'open' ? 'Close Job' : 'Reopen Job'}</MenuItem>
    </Menu>
  )};

  return (
    <div className={classes.root}>
      <List className={classes.list}>
      {map(jobs, (job, jobId) => {
        if (customer && customer.name !== job.customer) return null;
        if (filteredJobs && !has(filteredJobs, jobId)) return null;
        return (
          <ListItem
            key={jobId}
            button
            onClick={
              () => {
              //Find click events firing incorrectly
              setJob(prev => {
              if (prev) bugLog({prev, timestamp: Date.now()})
              return jobId
              });
              }
            }
          >
            {customer && 
              <ListItemIcon>
                {job.status === 'open'
                  ? <SlowMotionVideo style={{color: lightBlue[600]}}/>
                  : <OfflinePin style={{color: green[600]}} />
                }
              </ListItemIcon>
            }
            <ListItemText
              primary={job.workOrder}
              secondary={
                <Fragment>
                  <Typography
                    variant="body2"
                    component="span"
                    color="textPrimary"
                  >
                    {job.customer}
                  </Typography>
                </Fragment>
              }
            />
            <ListItemSecondaryAction>
              <IconButton color="inherit" onClick={e => setMenu({ t: e.currentTarget, id: jobId })}>
                <MoreVert />
              </IconButton>
              {renderMenu(jobId)}
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
      <ListItem className={classes.lastRow}></ListItem>
      </List>
      {!customer && 
      <Fab
        className={classes.fab}
        color="primary"
        onClick={() => setOpen(true)}
      >
        <Add />
      </Fab>}
      <NewJob
        open={open}
        onClose={() => {
          setOpen(false);
          setEdit('');
        }}
        edit={jobs && jobs[edit]}
        jobId={edit}
      />
      <Job
        open={!!job}
        onClose={() => {
          cancelJobItemFetch(job);
          cancelFetchReports(job);
          setJob('');
          
        }}
        jobId={job}
        job={jobs ? jobs[job] : {}}
      />
    </div>
  )
}

function mapStateToProps({ jobs, filteredJobs }) {
  return {
    jobs,
    filteredJobs
  }
}
export default connect(mapStateToProps, actions)(Jobs);
