import { combineReducers } from 'redux';
import authUser from 'reducers/authUser';
import customers from 'reducers/customers';
import jobs from 'reducers/jobs';
import items from 'reducers/jobItems';
import scanPlans from 'reducers/scanPlans';
import utt from 'reducers/utt';
import rebuild from 'reducers/rebuild';
import hydro from 'reducers/hydro';
import mpi from 'reducers/mpi';
import thread from 'reducers/thread';
import settings from 'reducers/settings';
import customerItems from 'reducers/customerItems';
import filteredJobs from 'reducers/filteredJobs';
import certsInProgress from 'reducers/makingCerts.js';




export default combineReducers({
  authUser,
  customers,
  jobs,
  items,
  scanPlans,
  rebuild,
  utt,
  hydro,
  mpi,
  thread,
	settings,
	certsInProgress,
	customerItems,
	filteredJobs
})