import { map, omit, size, isEqual, isObject, isArray, transform } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  LinearProgress,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { MoreVert, DeleteForever } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';
import clsx from 'clsx';
import { object, string, mixed, array } from 'yup';

import * as actions from 'actions/itemActions.js';
import { useConfirm } from 'components/confirm';
import Parts from 'components/parts';

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
function difference(origObj, newObj) {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
      }
    })
  }
  return changes(newObj, origObj)
}

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 840,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    margin: 'auto'
  },
  image: {
    objectFit: 'contain',
    marginTop: theme.spacing(1),
  },
  reading: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 52,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 65,
    },
    margin: theme.spacing(.6),
    '& input': {
      padding: '5px 8px',
      textAlign: 'center',
      fontSize: '.8em'
    },
    '& p': {
      margin: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: '.6em'
      }
    },
    '& label': {
      fontSize: '.9em'
    }
  },
  visual: {
    position: 'absolute',
    right: -12,
    top: 0,
    '& span': {
      fontSize: '.6em'
    },
    '& span:first-child': {
      padding: 0,
      height: 28,
      width: 28
    },
    '& input': {
      height: 30,
      width: 30
    }
  },
  readRow: {
    position: 'relative',
    paddingRight: 25
  },
  divider: {
    marginBottom: theme.spacing(.5)
  },
  report: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  accept: {
    backgroundColor: green[100],
  },
  reject: {
    backgroundColor: red[100]
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(2),
  }
}));


const Rebuild = props => {
  const {
    open,
    onClose,
    edit,
    items,
    jobId,
    createRBReport,
    editRBReport,
    deleteRBReport,
    uploadRBReport
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [progress, setProgess] = React.useState('');
  const [addParts, setAddParts] = React.useState(null);
  const [reinit, setReinit] = React.useState(true)
  return (
  <Formik
      enableReinitialize={Boolean(edit) || reinit}
      initialValues={edit
      ? {...edit, parts: edit.parts || {}}
      : {
        report: Date.now(),
        date: '',
        rebuilder: '',
        items: {},
        reports: [],
        parts: {},
        jobId
      }}
      onSubmit={(values, actions) => {
        return edit ?
        editRBReport(edit.id, values, () => {
          enqueueSnackbar('Report updated', { variant: 'success'});
          actions.resetForm();
          onClose();
        })
        :
        createRBReport(values, () => {
          enqueueSnackbar('Report added', { variant: 'success'});
          actions.resetForm();
          onClose();
        });
      }}
      validationSchema={
        object().shape({
          report: string().required(),
          date: string().required(),
          rebuilder: string().required(),
          items: mixed().test({
            name: 'items',
            test: value => size(value) !== 0,
            message: 'Please select at least one item'
          }),
          reports: array().min(1).required()
        })
      }
    >
      {(formikProps) => {
      const {
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        resetForm,
        initialStatus
      } = formikProps;
      const handleClose = () => {
        const changed = !isEqual(values, edit);
        const emptyParts = {parts: {}};
        const diff = difference(edit, values);
        const noParts = isEqual(emptyParts, diff);
        setReinit(true);
        if (edit && changed && !noParts) return confirm({
          description: 'You have unsaved changes, are you sure you want to close?'
        })
        .then(() => {
          onClose();
          resetForm();
        })
        .catch();
        if (!edit) map(values.reports, ({path}) => deleteRBReport(path));
        resetForm();
        onClose();
      };
      return (
      <Dialog open={open} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <DialogTitle>Rebuild</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} className={classes.report}>
            <Grid item xs={12} sm={7}>
              <TextField
                name="report"
                label="Report"
                value={values.report}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.report && errors.report)}
                helperText={touched.report && errors.report}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                name="date"
                label="Date"
                value={values.date}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.date && errors.date)}
                helperText={touched.date && errors.date}
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="rebuilder"
                label="Rebuilder"
                value={values.rebuilder}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.rebuilder && errors.rebuilder)}
                helperText={touched.rebuilder && errors.rebuilder}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
              <Grid item xs={12}>
                <Dropzone 
                  onDrop={files => {
                    files[0] && uploadRBReport(jobId, files[0], ({ progress, error, url, name, path, fullPath}) => {
                      if (error) enqueueSnackbar(error, {variant: 'error'});
                      if (progress) setProgess(progress);
                      if (url) {
                        setProgess('');
                        setReinit(true);
                        if (values.reports) values.reports.push({ url, name, path, fullPath});
                        const reports = values.reports || [{ url, name, path, fullPath}];
                        setFieldValue('reports', reports, false);
                        edit && editRBReport(edit.id, { ...edit, reports });
                        return;
                      }
                    });
                    
                  }}
                  accept='application/pdf'
                  onDropRejected={() => enqueueSnackbar('Only single .pdf files are allowed', { variant: 'warning'})}
                  multiple={false}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  }) => (
                    <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <TextField
                      className={clsx({
                        [classes.accept]: isDragAccept,
                        [classes.reject]: isDragReject
                      })}
                      label="Upload Rebuild Reports"
                      // value={progress ? <LinearProgress variant="determinate" value={progress} /> : ''}
                      placeholder="Click or drop files here to upload..."
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    </div>
                  )}
                </Dropzone>
              </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Reports</Typography>
              <List>
                {map(values.reports, ({ url, name, path }, rIndex) => {
                  return (
                    <ListItem
                      key={url}
                      component="a"
                      button
                      href={url}
                      target="_blank"
                    >
                      {name}
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => {
                          confirm({
                            description: 'Are you sure you want to delete this report file?'
                          })
                          .then(() => {
                            deleteRBReport(path, err => {
                              if (err) enqueueSnackbar(JSON.stringify(err), { variant: 'error' });
                              values.reports.splice(rIndex, 1);
                              setFieldValue('reports', values.reports);
                              edit && editRBReport(edit.id, { ...edit, reports: values.reports });
                              enqueueSnackbar('File deleted!', { variant: 'warning' });
                            });
                          });
                        }}>
                          <DeleteForever />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
                {progress &&  
                  <ListItem>
                    <div className={classes.progress}>
                      <LinearProgress variant="determinate" value={progress || 0} />
                    </div>
                  </ListItem>
                }
              </List>
            </Grid>
          </Grid>
          <List>
            {map(items, (item, itemId) => {
              return (
                <ListItem key={itemId}>
                  <Checkbox
                    checked={Boolean(values.items && values.items[itemId]) || false}
                    onChange={() => {
                      setFieldValue('items', 
                        values.items[itemId] ?
                        omit(values.items, itemId) || {}
                        : { ...values.items, [itemId]: true}
                        );
                    }}
                  />
                  <ListItemText
                    primary={item.asset}
                    secondary={item.description}
                  />
                  {values.items[itemId] &&
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => setAddParts(itemId)}>
                      <MoreVert />
                    </IconButton>
                  </ListItemSecondaryAction>}
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color="primary">{edit ? 'Update' : 'Submit'}</Button>
        </DialogActions>
      </Form>
      <Parts
        open={Boolean(addParts) || false}
        onClose={() => setAddParts(null)}
        itemId={addParts}
        jobId={jobId}
        asset={items[addParts] && items[addParts].asset}
        {...formikProps}
      />
      </Dialog>
      )}}
    </Formik>
  );
};



export default connect(null, actions)(Rebuild);