import { map, remove } from 'lodash';
import React from 'react';
import {
  InputBase,
  AppBar,
  Chip,
} from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  terms: {
    display: 'block',
    marginLeft: 64
  }
}));

const Chips = ({
  terms,
  deleteTerm
}) => (map(terms, (term, index) => {
      return <Chip
        key={index+term}
        label={term}
        onDelete={() => deleteTerm(index)}
      />
}))

const Filter = (props) => {
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const [terms, setTerms] = React.useState([]);
  return (
    <React.Fragment>
    <AppBar position="static" color="default">
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <form 
          onSubmit={e => {
            e.preventDefault();
            setTerms(prevState => {
              prevState.push(search);
              return prevState;
            });
            setSearch('');
          }}>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={e => setSearch(e.target.value)}
          value={search}
        />
        </form>
      </div>
      <div className={classes.terms}>
        <Chips
          terms={terms}
          classes={classes}
          deleteTerm={index => {
            const newTerms = remove(terms, (v,i) => (i!==index));
            setTerms(newTerms);
          }}
        />
      </div>
    </AppBar>
    {props.children}
    </React.Fragment>
  );
};

export default Filter;
