// import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';

const pipesInit = [
  [1, 1],
  [1, 1],
  [1, 1],
  [450, 1],
];

const gapsInit = [
  [1, 1],
  [150, 1],
  [300, 1],
  [450, 140],
]

const HEIGHT = 400;
const WIDTH = 400;

const SPEED = 30;

var frameRate = 1/40; // Seconds
var frameDelay = frameRate * 1000; // ms
// var loopTimer = false;

var ballInit = {
  position: {x: WIDTH/2, y: HEIGHT/2},
  velocity: {x: 0, y: 0},
  mass: 0.15, //kg
  radius: 10, // 1px = 1cm
  restitution: -0.8
};

var Cd = 0.47;  // Dimensionless
var rho = 1.22; // kg / m^3
var A = Math.PI * ballInit.radius * ballInit.radius / (10000); // m^2
var ag = 9.81;  // m / s^2
var mouseInit = {x: 0, y: 0, isDown: false};

const Canvas = ({ history }) => {
  const [ pipes, setPipes ] = useState(pipesInit);
  const [ gaps, setGaps ] = useState(gapsInit);
  const [ crash, setCrash ] = useState(false);
  const [ start, setStart ] = useState(true);
  const [mouse, setMouse] = useState(mouseInit);
  const [ball, setBall] = useState(ballInit);
  const [score, setScore] = useState(0);
  
  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  
  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  
  useEffect(() => {
    setTimeout(() => setStart(false), SPEED * 80);
  }, [crash]);
  
  const restart = () => {
    setPipes([
      [1, 1],
      [1, 1],
      [1, 1],
      [450, 1],
    ]);
    setGaps([
      [1, 1],
      [150, 1],
      [300, 1],
      [450, 140],
    ]);
    setCrash(false);
    setStart(true);
    setMouse(mouse);
    setBall(ballInit);
    setScore(0);
  };
  
  useInterval(() => {
      setPipes(prev => {
        if (prev[0][0] < -50) {
          prev.shift();
          prev.push([prev[prev.length - 1][0] + 150, 1]);
        }
        prev.map(pipe => (pipe[0] = pipe[0]-1));
        return prev;
      });
      setGaps(prev => {
        if (prev[0][0] < -50) {
          prev.shift();
          prev.push([prev[prev.length - 1][0] + 150, randomIntFromInterval(HEIGHT/20,HEIGHT*3/4)]);
        }
        prev.map(pipe => (pipe[0] = pipe[0]-1));
        return prev;
      });
      draw();
      loop();
  }, frameDelay);
  
  const loop = function() {
    if (!mouseInit.isDown && ball && ball.velocity) {
        // Do physics
            // Drag force: Fd = -1/2 * Cd * A * rho * v * v
        var Fx = -0.5 * Cd * A * rho * ball.velocity.x * ball.velocity.x * ball.velocity.x / Math.abs(ball.velocity.x);
        var Fy = -0.5 * Cd * A * rho * ball.velocity.y * ball.velocity.y * ball.velocity.y / Math.abs(ball.velocity.y);
        
        Fx = (isNaN(Fx) ? 0 : Fx);
        Fy = (isNaN(Fy) ? 0 : Fy);
        
            // Calculate acceleration ( F = ma )
        var ax = Fx / ball.mass;
        var ay = ag + (Fy / ball.mass);
        // Integrate to get velocity & position
        setBall({
          ...ball,
          velocity: {
            x: ball.velocity.x += ax*frameRate,
            y: ball.velocity.y += ay*frameRate,
          },
          position: {
            x:  ball.position.x += ball.velocity.x*frameRate*100,
            y: ball.position.y += ball.velocity.y*frameRate*100,
          }
        });
    }
    if (
      !start &&
      (
        ball.position.x  + ball.radius > pipes[1][0] &&
        ball.position.x  < pipes[1][0] + 50 + (ball.radius/2)
      ) &&
      ( 
        ball.position.y - ball.radius + 1 < gaps[1][1] || 
        ball.position.y  > gaps[1][1] + (HEIGHT/5)
      )
      
      ) {
      setCrash(true);
    }
    if (!crash && ball.position.x  === pipes[1][0] + 51 + (ball.radius/2)) setScore(score + 1);
    
    // Handle collisions
    if (ball.position.y > HEIGHT - ball.radius) {
      setBall({
        ...ball,
        velocity: { ...ball.velocity, y:  ball.velocity.y *= ball.restitution },
        position: { ...ball.position, y:  ball.position.y = HEIGHT - ball.radius}
      });
    }
    if (ball.position.x > HEIGHT - ball.radius) {
      setBall({
        ...ball,
        velocity: { ...ball.velocity, x:  ball.velocity.x *= ball.restitution },
        position: { ...ball.position, x:  ball.position.x = HEIGHT - ball.radius}
      });
    }
    if (ball.position.x < ball.radius) {
      setBall({
        ...ball,
        velocity: { ...ball.velocity, y:  ball.velocity.y *= ball.restitution },
        position: { ...ball.position, y:  ball.position.y = ball.radius}
      });
    }
  };
  
  const draw = crash ? ()=>{} : () => {
        const canvas = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        // const finger =  document.getElementById('finger');
        // const booger =  document.getElementById('booger');
        canvas.focus();
        // canvas.onmousemove = getMousePosition;
        canvas.onmousedown = jump;
        canvas.onkeydown =  e => {
          if (e.keyCode === 38) jump();
        };
        // canvas.onmouseup = mouseUp;
    
        ctx.fillStyle = 'green';
        ctx.clearRect(0,0,WIDTH,HEIGHT);
        pipes.forEach(point => ctx.fillRect(point[0], point[1], 50, HEIGHT));
        ctx.fillStyle = 'white';
        gaps.forEach(point => ctx.fillRect(point[0], point[1], 50, start ? HEIGHT : HEIGHT/5));
        ctx.fillStyle= 'red';
        // ctx.clearRect(0,0,width,height);
        // ctx.drawImage(img, 10, 10);
        ctx.save();
        ctx.translate(ball.position.x, ball.position.y);
        // ctx.drawImage(booger, 0, 0, ball.radius*2, ball.radius*2)
        ctx.beginPath();
        ctx.arc(0, 0, ball.radius, 0, Math.PI*2, true);
        ctx.fill();
        ctx.closePath();
        
        ctx.restore();
        // loopTimer = setInterval(loop, frameDelay);
    };
    
  const jump = () => {
    setBall({
        ...ball,
        velocity: { ...ball.velocity, y:  -2.5},
        //position: { ...ball.position, y:  ball.position.y = ball.radius}
      });
  };
  return (
    <div className=".disable-dbl-tap-zoom">
      <canvas id="canvas" height={HEIGHT} width={WIDTH} style={{ border: '1px solid gray'}} tabIndex='1'></canvas>
      <div>Score: {score}</div>
      {/* <img src={require('images/finger.png')} alt="finger" id="finger" style={{display: 'none'}}/>
      <img src={require('images/booger.png')} alt="booger" id="booger" style={{display: 'none'}}/> */}
      {
        crash ?
        <div> CRASHED!! <button onClick={() => restart(() => history.push('/flappy'))}>Restart</button></div>
        :
        <button onClick={jump}>FLAP</button>
      }
      
    </div>
  );
};

export default withRouter(Canvas);
