import { map } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Dropzone from 'react-dropzone';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { green, red } from '@material-ui/core/colors';

import { uploadMTR, deleteMtr } from 'actions/itemActions';

const useStyles = makeStyles(theme => ({
  accept: {
    backgroundColor: green[100],
  },
  reject: {
    backgroundColor: red[100]
  },
}));

const MTRs = ({
  itemId,
  item,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(null);
  const mtrAttachments = item && item.mtrs;
  return (
    <Dialog
      open={Boolean(itemId)}
      onClose={onClose}
    >
      <DialogTitle>Upload MTRs for {item && item.asset}</DialogTitle>
      <DialogContent>
        <Dropzone 
          onDrop={files => {
            files[0] && dispatch(uploadMTR(itemId, files[0], ({ progress, error, url}) => {
              if (error) enqueueSnackbar(error, {variant: 'error'});
              if (progress) setProgress(progress);
              if (url) {
                setProgress(null);
                return;
              }
            }));
            
          }}
          accept='application/pdf'
          onDropRejected={() => enqueueSnackbar('Only single .pdf files are allowed', { variant: 'warning'})}
          multiple={false}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => (
            <div {...getRootProps()}>
            <input {...getInputProps()} />
            {progress
            ? <LinearProgress variant="determinate" value={progress} />
            : <TextField
              className={clsx({
                [classes.accept]: isDragAccept,
                [classes.reject]: isDragReject
              })}
              label="Upload MTR File"
              //value={progress ? <LinearProgress variant="determinate" value={progress} /> : ''}
              placeholder="Click or drop files here to upload..."
              variant="outlined"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />}
            </div>
          )}
        </Dropzone>
        <List>
          {map(mtrAttachments, (attachment, attachmentId) => {
            return <ListItem
              key={attachmentId}
              button
              component="a"
              href={attachment.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemText
                primary={attachment.name}
              />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={()=> dispatch(deleteMtr(itemId, attachmentId, attachment.filePath))}
                >
                  <DeleteForever />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          })}
        </List>
      </DialogContent>
    </Dialog>  
  )
}

export default MTRs;