import { map, uniqBy, values as _values, pickBy, mapValues, includes, omit, size, uniq as _uniq, difference } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  LinearProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { green, red } from '@material-ui/core/colors';
import { MoreVert, Close, DeleteForever } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';
import { object, string, mixed, array } from 'yup';
import clsx from 'clsx';

import * as actions from 'actions/itemActions';
import { useConfirm } from 'components/confirm';


const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 840,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    margin: 'auto'
  },
  image: {
    objectFit: 'contain',
    marginTop: theme.spacing(1),
  },
  reading: {
    [theme.breakpoints.down('md')]: {
      maxWidth: 52,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 65,
    },
    margin: theme.spacing(.6),
    '& input': {
      padding: '5px 8px',
      textAlign: 'center',
      fontSize: '.8em'
    },
    '& p': {
      margin: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: '.6em'
      }
    },
    '& label': {
      fontSize: '.9em'
    }
  },
  visual: {
    position: 'absolute',
    right: -6,
    top: 0,
    '& span': {
      fontSize: '.5em'
    },
    '& span:first-child': {
      padding: 0,
      height: 26,
      width: 26
    },
    '& input': {
      height: 28,
      width: 28
    }
  },
  visualIndication: {
    position: 'absolute',
    right: -30,
    top: 0,
    '& span': {
      fontSize: '.5em'
    },
    '& span:first-child': {
      padding: 0,
      height: 26,
      width: 26
    },
    '& input': {
      height: 28,
      width: 28
    }
  },
  readRow: {
    position: 'relative',
    paddingRight: 25
  },
  divider: {
    marginBottom: theme.spacing(.5)
  },
  report: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
  },
  accept: {
    backgroundColor: green[100],
  },
  reject: {
    backgroundColor: red[100]
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(2),
  }
}));


const Utt = ({
  open,
  onClose,
  edit,
  items,
  jobId,
  completed,
  workOrder,
  createUTTReport,
  editUTTReport,
  deleteUTTReport,
  uploadUTTReport
})=> {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [progress, setProgess] = React.useState('');
  const remainingItems = omit(items, edit && map(edit.items, (eItems, Eid) => (Eid)));
  const uniq = uniqBy(_values(pickBy(items, {utt: true})), o => (o.scanPlan && o.scanPlan.url));
  const scans = map(uniq, ({scanPlanId, scanPlan}) => (scanPlanId && { ...scanPlan, scanPlanId }));
  const visOnly = pickBy(items, {visual: true, utt: false})
  const initReadings = () => (mapValues(remainingItems, item => {
    const res = {
      readings: item.scanPlan ? new Array(parseInt(item.scanPlan.readings, 10)).fill('') : null,
      mins: item.mins || null,
      scanUrl: item.scanPlan ? item.scanPlan.url : null,
      visual: false
    };
    return res;
  }));
  const [_readings] = React.useState(initReadings())
  const editWithItems = {
          ...edit,
          items: { ...edit.items, ..._readings }
        }
  return (
    <Formik
      enableReinitialize={Boolean(edit)}
      initialValues={edit 
      ?  editWithItems
      : {
        report: '',
        date: '',
        inspector: '',
        items: _readings,
        reports: []
      }}
      validationSchema={
        object().shape({
          report: string().required(),
          date: string().required(),
          inspector: string().required(),
          items: mixed().test({
            name: 'items',
            test: value => {
            const testValue = _uniq(map(value, v => v.visual)).join() !== 'false'
            return testValue
            },
            message: 'Please select at least one item'
          }),
          reports: array().min(1, "At least one report is required").required(),
        })
      }
      onSubmit={(values, actions) => {
        const trimmed = {
          ...values,
          items: pickBy(values.items, item => (
            item.visual && item.visOnly
            || includes(map(item.readings, val => (Boolean(val))), true)
          )),
          jobId,
          workOrder
        };
        return edit ?
        editUTTReport(edit.id, trimmed, () => {
          enqueueSnackbar('Report updated', { variant: 'success'});
          actions.resetForm();
          onClose();
        })
        :
        createUTTReport(trimmed, () => {
          enqueueSnackbar('Report added', { variant: 'success'});
          actions.resetForm();
          onClose();
        });
      }}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        submitForm
      }) => {
      const handleClose = () => {
      
        if (!edit) {
          map(values.reports, ({path}) => deleteUTTReport(path));
        }
        const editDiff = difference(_values(omit(values, 'items')), _values(omit(editWithItems, 'items')));
        const editItemDiff = difference(_values(values.items), _values(editWithItems.items));
        const editCombined = [...editDiff, ...editItemDiff];
        return !edit || editCombined.length === 0  
        ? onClose()
        : confirm({
            title: 'Unsaved Changes!',
            description: 'You save unsaved changes, you still want to exit?',
            confirmationText: "Don't Save",
            cancellationText: 'Cancel',
            dialogProps: {}
          })
          .then(onClose)
          .catch(f => f)
      };
      return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <Form>
            <DialogTitle style={{ position: 'relative' }}>
              Enter UT Readings
              <IconButton className={classes.close} onClick={handleClose}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Card className={classes.card} raised>
                <CardHeader
                  title="Ultrasonic Thickness Report"
                  action={
                    <IconButton><MoreVert /></IconButton>
                  }
                />
                <CardContent>
                  <Grid container spacing={3} className={classes.report}>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        name="report"
                        label="Report"
                        value={values.report}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        error={Boolean(touched.report && errors.report)}
                        helperText={touched.report && errors.report}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        name="date"
                        label="Date"
                        value={values.date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        error={Boolean(touched.date && errors.date)}
                        helperText={touched.date && errors.date}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="inspector"
                        label="Inspector"
                        value={values.inspector}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        error={Boolean(touched.inspector && errors.inspector)}
                        helperText={touched.inspector && errors.inspector}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Dropzone 
                        onDrop={files => {
                          files[0] && uploadUTTReport(jobId, files[0], ({ progress, error, url, name, path, fullPath}) => {
                            if (error) enqueueSnackbar(error, {variant: 'error'});
                            if (progress) setProgess(progress);
                            if (url) {
                              setProgess('');
                              if (values.reports) values.reports.push({ url, name, path, fullPath});
                              const reports = values.reports || [{ url, name, path, fullPath}];
                              setFieldValue('reports', reports);
                              edit && editUTTReport(edit.id, { ...edit, reports });
                            }
                          });
                          
                        }}
                        accept='application/pdf'
                        onDropRejected={() => enqueueSnackbar('Only single .pdf files are allowed', { variant: 'warning'})}
                        multiple={false}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                        }) => (
                          <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <TextField
                            className={clsx({
                              [classes.accept]: isDragAccept,
                              [classes.reject]: isDragReject
                            })}
                            label="Upload Ultrasonic Thickness Reports"
                            // value={progress ? <LinearProgress variant="determinate" value={progress} /> : ''}
                            placeholder="Click or drop files here to upload..."
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={Boolean(touched.reports && errors.reports)}
                            helperText={touched.reports && errors.reports}
                          />
                          </div>
                        )}
                      </Dropzone>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">Reports</Typography>
                      <List>
                        {map(values.reports, ({ url, name, path }, rIndex) => {
                          return (
                            <ListItem
                              key={url}
                              component="a"
                              button
                              href={url}
                              target="_blank"
                            >
                              {name}
                              <ListItemSecondaryAction>
                                <IconButton onClick={() => {
                                  confirm({
                                    description: 'Are you sure you want to delete this report file?'
                                  })
                                  .then(() => {
                                    deleteUTTReport(path, err => {
                                      if (err) return enqueueSnackbar(err, { variant: 'error' });
                                      values.reports.splice(rIndex, 1);
                                      setFieldValue('reports', values.reports);
                                      edit && editUTTReport(edit.id, { ...edit, reports: values.reports });
                                      enqueueSnackbar('File deleted!', { variant: 'warning' });
                                    });
                                  });
                                }}>
                                  <DeleteForever />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                        {progress &&  
                          <ListItem>
                            <div className={classes.progress}>
                              <LinearProgress variant="determinate" value={progress || 0} />
                            </div>
                          </ListItem>
                        }
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {map(scans, scan => {
                if (!scan) return null;
                const { scanPlanId, url, readings } = scan;
                const splitReadings = readings < 6 ? 6 : 12;
                return (
                  <Card className={classes.card} key={scanPlanId} raised>
                    <CardMedia
                      component="img"
                      alt="Scan Plan"
                      height="140"
                      image={url}
                      title="Scan Plan"
                      className={classes.image}
                    />
                    <CardContent>
                      <Grid container justifyContent="center" alignItems="center">
                        {map(pickBy(items, { scanPlanId }), (item, itemId) => {
                          if (!item.utt) return null;
                          const { asset, description, uttResults, mins } = item;
                          const uttValues = values.items[itemId] && values.items[itemId].readings;
                          return (
                            <React.Fragment key={itemId}>
                              <Grid item xs={12} sm={splitReadings}>
                                <Typography noWrap className={classes.asset}>{`${asset} - ${description}`}</Typography>
                              </Grid>
                              <Grid
                                container
                                item xs={12}
                                sm={splitReadings}
                                justifyContent="center"
                                alignItems="center"
                                className={classes.readRow}
                                >
                                {map(uttValues, (read, index) => {
                                  return (
                                    <TextField
                                      key={index.toString()}
                                      value={read}
                                      variant="outlined"
                                      label={(index + 1).toString()}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      onChange={e => {
                                        const { value } = e.target;
                                        uttValues[index] = value;
                                        setFieldValue('items',
                                          { 
                                            ...values.items,
                                            [itemId]: {
                                              ...values.items[itemId],
                                              readings: uttValues
                                            }
                                          });
                                      }}
                                      helperText={`Min: ${mins[index]}`}
                                      className={classes.reading}
                                      error={uttResults && uttValues[index] < mins[index] && uttValues[index] > 0}
                                    />
                                  );
                                })}
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.items[itemId] && values.items[itemId].visual}
                                      onChange={() => setFieldValue('items', {
                                        ...values.items,
                                        [itemId] : {
                                          ...values.items[itemId],
                                          visual: !values.items[itemId].visual
                                        }
                                      }
                                      )}
                                    />}
                                  label="Visual"
                                  labelPlacement="bottom"
                                  className={classes.visual}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={(values.items[itemId] && values.items[itemId].visualIndication) || false}
                                      onChange={() => setFieldValue('items', {
                                        ...values.items,
                                        [itemId] : {
                                          ...values.items[itemId],
                                          visualIndication: !values.items[itemId].visualIndication
                                        }
                                      }
                                      )}
                                    />}
                                  label="Ind."
                                  labelPlacement="bottom"
                                  className={classes.visualIndication}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Divider className={classes.divider}/>
                              </Grid>
                            </React.Fragment>
                          );
                        })}
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
              <Card className={classes.card} raised>
                <CardHeader
                  title="Visual Only"
                />
                <CardContent>
                <List>
                  {
                    map(visOnly, ({asset, description}, itemId) => {
                      return (
                        <ListItem key={itemId}>
                          <Typography noWrap className={classes.asset}>{`${asset} - ${description}`}</Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.items[itemId] && values.items[itemId].visual}
                                onChange={() => setFieldValue('items', {
                                  ...values.items,
                                  [itemId] : {
                                    ...values.items[itemId],
                                    visual: !values.items[itemId].visual,
                                    visOnly: true
                                  }
                                }
                                )}
                              />}
                            label="Visual"
                            labelPlacement="bottom"
                            className={classes.visual}
                          />
                        </ListItem>
                      )
                    })
                  }
                </List>
                </CardContent>
              </Card>
              {map(errors, (error, errorId) => {
                if (touched[errorId]) {
                  return <Alert key={errorId} severity="error">{error}</Alert>
                }
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" color="primary">{edit ? 'Update' : 'Submit'}</Button>
            </DialogActions>
          </Form>
        </Dialog>
      )}}
    </Formik>
  );
};



export default connect(null, actions)(Utt);


