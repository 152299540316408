import { database } from 'firebase_config';

export const FETCH_ALL_CUSTOMERS = 'FETCH_ALL_CUSTOMERS';
export const FETCH_CUSTOMER_CONTACTS = 'FETCH_CUSTOMER_CONTACTS';

const customers = database.ref('customers/customers');

export function fetchAllCustomers() {
  return dispatch => customers.once('value', snapshot => {
    dispatch({
      type: FETCH_ALL_CUSTOMERS,
      payload: snapshot ? snapshot.val() : {}
    });
  });
}

export function fetchCustomerContacts(customerId) {
  return (dispatch, getState) => customers.child(`${customerId}/contacts`).once('value', snapshot => {
    const currentCustomer = getState().customers[customerId];
    const composedCustomer = {
      [customerId]: {
        ...currentCustomer,
        contacts: snapshot ? snapshot.val() : null
      }
    }
    dispatch({
      type: FETCH_CUSTOMER_CONTACTS,
      payload: composedCustomer
    })
  })
}

export function writeCustomerContact(customerId, values, cb) {
  const id = values.id || customers.child(`${customerId}/contacts`).push().key;
  return () => {
    return customers.child(`${customerId}/contacts/${id}`).update({ ...values, id }).then(cb);
  }
}