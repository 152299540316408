import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles, createTheme,ThemeProvider } from '@material-ui/core/styles';

import * as actions from 'actions/itemActions.js';


const useStyles = makeStyles(theme => ({
  asset: {
    marginBottom: theme.spacing(2)
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      light: '#ffb74d',
      main: '#ffa726',
      dark: '#f57c00'
    }
    
  },
});

const FailItem = props => {
  const {
    open,
    onClose,
    itemId,
    items,
    editItem
  } = props;
  const classes = useStyles();
  const [reason, setReason] = React.useState(null);
  
  const item = items && items[itemId];
  const handleFail = () => {
    const values = {
      ...item,
      fail: reason
    }
    editItem(itemId, values, onClose)
  }
  const handleReinstate = () => {
    const values = {
      ...item,
      fail: null
    }
    editItem(itemId, values, onClose)
  }
  React.useEffect(()=> {
    setReason(item && item.fail)
  }, [item])
  return item 
    ? <Dialog open={open} onClose={onClose}>
      <DialogTitle>Fail Item</DialogTitle>
      <DialogContent>
        <Typography className={classes.asset}>{`${item.asset}: ${item.description}`}</Typography>
        <TextField
          value={reason}
          onChange={e => setReason(e.target.value)}
          multiline
          minRows={2}
          label='Reason'
          helperText='Please list reason for failure'
          variant='outlined'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleReinstate} variant="contained">Reinstate</Button>
        <ThemeProvider theme={theme}>
          <Button onClick={handleFail} variant="contained" color="primary">Fail</Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  : null;
};

function mapStateToProps({ items }) {
  return {
    items
  }
}

export default connect(mapStateToProps, actions)(FailItem);



