import React from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { object, string, array, number } from 'yup';
import Dropzone from 'react-dropzone';
import clsx from 'clsx';
import { green, red } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';

import * as actions from 'actions/itemActions.js';


const useStyles = makeStyles(theme => ({
  input: {
    marginBottom: theme.spacing(2)
  },
  accept: {
    backgroundColor: green[100],
  },
  reject: {
    backgroundColor: red[100]
  },
}));


const NewScan = props => {
  const {
    open,
    onClose,
    addScanPlan
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      initialValues={{
        readings: 0,
        title: '',
        file: []
      }}
      validationSchema={
        object().shape({
          readings: number().moreThan(0),
          title: string().required(),
          file: array().min(1)
        })
      }
      onSubmit={(values, actions) => {
        addScanPlan(values, (res) => {
          actions.resetForm();
          onClose();
          switch(res) {
            case res.error:
              enqueueSnackbar(res.error, { variant: 'error' });
              break;
            case res.success:
              enqueueSnackbar(res.success, { variant: 'success' });
              break;
            default:
              break;
          }
        })
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur
      }) => {
      return (
        <Dialog open={open} onClose={onClose}>
          <Form>
            <DialogTitle>New Scan</DialogTitle>
            <DialogContent>
              <TextField
                name="title"
                label="Title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.input}
              />
              <TextField
                name="readings"
                label="No. of Readings"
                type="number"
                value={values.readings}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                error={Boolean(touched.readings && errors.readings)}
                helperText={touched.readings && errors.readings}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.input}
              />
              <Dropzone 
                onDrop={files => {
                  files[0] && setFieldValue('file', files);
                  }}
                accept="image/png, image/jpeg"
                multiple={false}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => (
                  <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <TextField
                    className={clsx({
                      [classes.accept]: isDragAccept,
                      [classes.reject]: isDragReject,
                      [classes.input]: true
                    })}
                    label="Scan Plan Image"
                    // value={progress ? <LinearProgress variant="determinate" value={progress} /> : ''}
                    placeholder="Click or drop files here to upload..."
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(touched.file && errors.file)}
                    helperText={touched.file && errors.file}
                  />
                  </div>
                )}
              </Dropzone>
              {values.file[0] &&
              <List>
                <ListItem>
                  <ListItemText
                    primary={values.file[0].name}
                  />
                </ListItem>
              </List>}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit" color="primary" variant="contained">Add Scan Plan</Button>
            </DialogActions>
            </Form>
        </Dialog>
      )}}
    </Formik>
  );
};



export default connect(null, actions)(NewScan);


