import { size, map } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
  Box,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import { Create } from '@material-ui/icons'

import * as actions from 'actions/customerActions';
import CustomerContact from 'components/customerContact'

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  contactInfo: {
    marginLeft: theme.spacing(2),
  },
}));

const ContactListDialog = ({ customer, fetchCustomerContacts }) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const classes = useStyles();
  const contacts = customer?.contacts;
  const customerId = customer?.id
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleEdit = (id) => {
    setEdit(id)
  }
  
  React.useEffect(() => {
    fetchCustomerContacts(customerId)
  }, [open, edit])

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0
      }}
    >
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Portal Contact List
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="contact-list-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="contact-list-dialog-title">
          <Typography>Contact List</Typography>
          <CustomerContact
            customerId={customerId}
            edit={edit}
            setEdit={setEdit}
          />
        </DialogTitle>
        <DialogContent>
          {contacts && size(contacts) !== 0 ? (
            <List>
              {map(contacts, (contact, contactId) => (
                <ListItem key={contactId} className={classes.listItem}>
                  <ListItemText
                    primary={`${contact.displayName}`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.contactInfo}
                        >
                          Email: {contact.email}
                        </Typography>
                        <br />
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.contactInfo}
                        >
                          Phone: {contact.phoneNumber}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => handleEdit(contactId)}
                  >
                    <Create />
                  </IconButton>
                </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            ) : (
            <Typography>No contacts found.</Typography>
          
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

function mapStateToProps({customers}) {
  return {
    customers
  }
}

export default connect(mapStateToProps, actions)(ContactListDialog);