import { FETCH_SETTINGS } from 'actions/index.js';

const INITIAL_STATE = {
}

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_SETTINGS:
            return action.payload;
        default:
            return state;
    }
}