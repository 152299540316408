import { map, range, remove, filter, includes, lowerCase, every, split, some } from "lodash";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Switch,
  FormGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  MenuItem,
  Typography,
  Button,
  Divider,
  Radio,
  RadioGroup,
  Fab,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Add, DeleteForever } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import { database } from 'firebase_config';

import NewScan from "components/newScan";

import * as actions from "actions/itemActions";

function mapStateToProps({ scanPlans, rebuildForms }) {
  return {
    scanPlans,
    rebuildForms,
  };
}

const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(2),
    maxWidth: 420,
  },
  reading: {
    margin: theme.spacing(1),
    maxWidth: 180,
  },
  thread: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  qty: {
    margin: theme.spacing(1),
    maxWidth: 80,
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  scanPic: {
    maxWidth: "75%",
    margin: "auto",
    maxHeight: 375,
    overflowY: "hidden",
    scroll: "auto",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  fab: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  delete: {
    height: "48px",
    position: "absolute",
    right: theme.spacing(-1),
    top: "50%",
    transform: "translate(100%,-50%)",
  },
  threadRow: {
    position: "relative",
  },
  table: {
    
    '& th' : {
      fontSize: '0.7em',
      padding: `${theme.spacing(.8)}px`
    },
    '& td' : {
      fontSize: '0.7em',
      padding: `${theme.spacing(.8)}px`,
      textAlign: 'center',
    },
  },
  tableRadio: {
    padding: 0
  }
}));
/*
 * ========================================
 * Item Step
 * ========================================
 */

export const Item = connect(
  null,
  actions
)(
  ({
    formikProps: {
      handleChange,
      handleBlur,
      values,
      errors,
      touched,
      jobId,
      setFieldValue,
    },
    validateAssetNumber,
  }) => {
    const classes = useStyles();
    const [descriptions, setDescriptions] = React.useState([]);
    React.useEffect(() => {
      const fetchDescriptions = async () => {
        const desc = await database.ref("repair_work/itemDescriptions").once('value').then(s=>s.val());
        return setDescriptions(desc);
      };
      fetchDescriptions();
    }, [])
    return (
      <div>
        <Typography variant="h4">Item Information</Typography>
        <Divider className={classes.divider} />
        {["asset", "description", "oem", "serial", "serviceType", "tempRating"].map(
          (field) => field === "description" ?
          <Autocomplete
            key={field}
            freeSolo
            options={descriptions}
            getOptionLabel={o=>o}
            getOptionSelected={(o,v) => o === v}
            value={values[field] || ''}
            inputValue={values[field] || ''}
            filterOptions={(o,s) => {
              const terms = split(values[field], " ")
              return filter(o, op => {
              const res = every(terms, term => {
              
                const hasQuotes = some(['"', "'"], ch => includes(term, ch));
                
                return hasQuotes ? includes(op, term) : includes(lowerCase(op), lowerCase(term))
              });
              return res
              })
            }}
            onChange={(e, nv) => {
              setFieldValue(field, nv)
            }}
            onInputChange={(e, nv) => {
              setFieldValue(field, nv)
            }}
            name={field}
            renderInput={params => 
            <TextField
              {...params}
              className={classes.input}
              fullWidth
              label={field.replace(/^\w/, (c) => c.toUpperCase())}
              variant="outlined"
              //onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched[field] && errors[field])}
              helperText={touched[field] && errors[field]}
            />}
          />
          :(
            <TextField
              className={classes.input}
              key={field}
              name={field}
              fullWidth
              label={field.replace(/^\w/, (c) => c.toUpperCase())}
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[field]}
              error={Boolean(touched[field] && errors[field])}
              helperText={touched[field] && errors[field]}
            />
          )
        )}
      </div>
    );
  }
);

/*
 * ========================================
 * Visual Step
 * ========================================
 */

export const Visual = ({ formikProps: { handleChange, values } }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4">Visual Inspection</Typography>
      <Divider className={classes.divider} />
      <FormGroup>
        {["visual", "visualPics"].map((field) => (
          <FormControlLabel
            className={classes.input}
            key={field}
            control={
              <Switch
                name={field}
                onChange={handleChange}
                value={values[field]}
                checked={values[field]}
              />
            }
            label={
              field === "visual"
                ? "Require visual inspection"
                : "Require photo evidence of visual dispositon"
            }
          />
        ))}
      </FormGroup>
    </div>
  );
};

/*
========================================
UTT Step
========================================
*/

export const UTT = connect(
  mapStateToProps,
  actions
)(
  ({
    formikProps: {
      setFieldValue,
      handleChange,
      handleBlur,
      values,
      errors,
      touched,
    },
    fetchScanPlans,
    scanPlans,
  }) => {
    const classes = useStyles();
    const [openNew, setOpenNew] = React.useState(false);
    React.useEffect(fetchScanPlans, [fetchScanPlans]);
    return (
      <div>
        <Typography variant="h4">Ultrasonic Thickness Inspection</Typography>
        <Fab
          size="small"
          className={classes.fab}
          onClick={() => setOpenNew(true)}
        >
          <Add />
        </Fab>
        <NewScan open={openNew} onClose={() => setOpenNew(false)} />
        <Divider className={classes.divider} />
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="utt"
                onChange={handleChange}
                value={values.utt}
                checked={values.utt}
              />
            }
            label="Require Ultrasonic Thickness Inspection"
            className={classes.input}
          />
          {values.utt && scanPlans ? (
            <div>
              <FormGroup row>
                <TextField
                  name="scanPlanId"
                  onChange={(e) => {
                    handleChange(e);
                    const id = e.target.value;
                    setFieldValue("scanPlan", id ? scanPlans[id] : "");
                    setFieldValue(
                      "mins",
                      id ? range(0, scanPlans[e.target.value].readings, 0) : []
                    );
                  }}
                  select
                  value={values.scanPlanId}
                  error={Boolean(touched.scanPlanId && errors.scanPlanId)}
                  helperText={touched.scanPlanId && errors.scanPlanId}
                  variant="outlined"
                  fullWidth
                  label="Scan Plan"
                  className={classes.input}
                >
                  <MenuItem value={``} />
                  {map(scanPlans, (scanPlan, scanPlanId) => (
                    <MenuItem value={scanPlanId} key={scanPlanId}>
                      {scanPlan.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormGroup>
              {values.scanPlanId ? (
                <img
                  src={values.scanPlan.url}
                  alt="Plan Missing"
                  className={classes.scanPic}
                />
              ) : null}
              <FormGroup row>
                {values.scanPlan && values.scanPlan.readings
                  ? map(range(values.scanPlan.readings), (i) => {
                      return (
                        <TextField
                          key={i.toString()}
                          className={classes.reading}
                          type="number"
                          value={values.mins[i] || 0}
                          inputProps={{
                            step: 0.01,
                          }}
                          variant="outlined"
                          onChange={(e) => {
                            values.mins[i] = e.target.value;
                            setFieldValue("mins", values.mins);
                          }}
                          label={`Reading ${i + 1}`}
                        />
                      );
                    })
                  : null}
              </FormGroup>
            </div>
          ) : null}
        </FormGroup>
      </div>
    );
  }
);

/*
========================================
Rebuild Step
========================================
*/

export const Rebuild = connect(
  mapStateToProps,
  actions
)(
  ({
    formikProps: {
      setFieldValue,
      handleChange,
      handleBlur,
      values,
      errors,
      touched,
    },
    //fetchRebuildForms,
    rebuildForms,
  }) => {
    const classes = useStyles();
    return (
      <div>
        <Typography variant="h4">Item Rebuild/Repack</Typography>
        <Divider className={classes.divider} />
        <FormGroup>
          <FormControlLabel
            className={classes.input}
            control={
              <Switch
                name="rebuild"
                onChange={handleChange}
                value={values.rebuild}
                checked={values.rebuild}
              />
            }
            label="Require Rebuild"
          />
          {values.rebuild && rebuildForms ? (
            <div>
              <TextField className={classes.input}>
                <MenuItem value="" />
                {map(rebuildForms, (rebuildForm, rebuildFormId) => (
                  <MenuItem value={rebuildFormId} key={rebuildFormId}>
                    {rebuildForm.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          ) : null}
        </FormGroup>
      </div>
    );
  }
);

/*
========================================
Hydro Step
========================================
*/

export const Hydro = ({
  formikProps: {
    setFieldValue,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
  },
}) => {
  const classes = useStyles();
  const [type, setType] = React.useState("weco");
  const max = (pressure) => {
    const p = parseInt(pressure)
    return (p > 10000 ? p + 500 : p + p * 0.05);
  }
  return (
    <div>
      <Typography variant="h4">Hydrostatic Pressure Testing</Typography>
      <Divider className={classes.divider} />
      <FormGroup>
        <FormControlLabel
          className={classes.input}
          control={
            <Switch
              name="hydro"
              onChange={handleChange}
              value={values.hydro}
              checked={values.hydro}
            />
          }
          label="Require Hydro"
        />
        {values.hydro ? (
          <Fragment>
            <TextField
              className={classes.input}
              value={values.wp}
              name="wp"
              type="number"
              fullWidth
              label="Working Pressure"
              variant="outlined"
              onChange={(e) => {
                handleChange(e);
                const { value } = e.target;
                setFieldValue(
                  "tp",
                  type === "weco"
                    ? value * 1.5
                    : type === "ansi"
                    ? value
                    : value < 5000
                    ? value * 2
                    : value * 1.5
                );
                const intervals = map(values.intervals, (interval) => {
                  return interval.holdType === "high"
                    ? { ...interval, max: [max(value)], target: [value] }
                    : interval;
                });
                setFieldValue("intervals", intervals);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.wp && errors.wp)}
              helperText={touched.wp && errors.wp}
            />
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Type</FormLabel>
              <RadioGroup
                name="testType"
                value={type}
                onChange={(e) => {
                  const { value } = e.target;
                  const { wp } = values;
                  setType(value);
                  setFieldValue(
                    "tp",
                    value === "weco"
                      ? wp * 1.5
                      : value === "ansi"
                      ? wp
                      : wp < 5000
                      ? wp * 2
                      : wp * 1.5
                  );
                }}
                row
              >
                <FormControlLabel
                  value="weco"
                  control={<Radio />}
                  label="Weco"
                />
                <FormControlLabel value="api" control={<Radio />} label="API" />
                <FormControlLabel
                  value="ansi"
                  control={<Radio />}
                  label="ANSI"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              className={classes.input}
              value={values.tp || 0}
              name="tp"
              type="number"
              fullWidth
              label="Integrity Test Pressure"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.tp && errors.tp)}
              helperText={touched.tp && errors.tp}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography variant="h5">Intervals</Typography>
            <Divider />
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Add</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Target Pressure</TableCell>
                  <TableCell>Maximum Pressure</TableCell>
                  <TableCell>Duration</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {map(values.intervals, (interval, intervalIndex) => {
                  const { holdType, target, max, duration, name } = interval;
                  return (
                    <TableRow key={intervalIndex}>
                      <TableCell><Radio size="small" className={classes.tableRadio}/></TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>{holdType}</TableCell>
                      <TableCell>{target} psi</TableCell>
                      <TableCell>{max} psi</TableCell>
                      <TableCell>{(duration/60).toFixed()} min</TableCell>
                      <TableCell>EDIT</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Fragment>
        ) : null}
      </FormGroup>
    </div>
  );
};

export const Thread = ({
  formikProps: { setFieldValue, handleChange, values },
}) => {
  const classes = useStyles();
  const threads = useSelector(
    (state) => state.settings && state.settings.threads
  );
  return (
    <div>
      <Typography variant="h4">Thread Inspection</Typography>
      <Divider className={classes.divider} />
      <FormGroup>
        <FormControlLabel
          className={classes.input}
          control={
            <Switch
              name="thread"
              onChange={handleChange}
              value={values.thread}
              checked={values.thread}
            />
          }
          label="Require Thread Check"
        />
        {values.thread ? (
          <div>
            {map(values.profiles, (profile, index) => {
              return (
                <FormGroup
                  row
                  key={index.toString()}
                  className={classes.threadRow}
                >
                  <TextField
                    className={classes.thread}
                    value={profile.thread}
                    label="Thread Callout"
                    select
                    variant="outlined"
                    onChange={(e) => {
                      values.profiles[index] = {
                        thread: e.target.value,
                        qty: values.profiles[index].qty,
                        profile:
                          threads[e.target.value] &&
                          threads[e.target.value].profile,
                        name:
                          threads[e.target.value] &&
                          threads[e.target.value].name,
                      };
                      setFieldValue("profiles", values.profiles);
                    }}
                  >
                    <MenuItem value="" />
                    {map(threads, (thread, threadId) => (
                      <MenuItem key={threadId} value={threadId}>
                        {thread.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.qty}
                    value={profile.qty}
                    label="Qty"
                    variant="outlined"
                    type="number"
                    onChange={(e) => {
                      values.profiles[index] = {
                        ...values.profiles[index],
                        qty: e.target.value,
                      };
                      setFieldValue("profiles", values.profiles);
                    }}
                  />
                  <IconButton
                    className={classes.delete}
                    onClick={() => {
                      const profileArray = values.profiles;
                      remove(profileArray, (v, i) => i === index);
                      setFieldValue("profiles", [...profileArray]);
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                </FormGroup>
              );
            })}
            <Button
              onClick={() => {
                if (values.profiles) {
                  values.profiles.push({ thread: "", qty: 1 });
                  return setFieldValue("profiles", values.profiles);
                }
                return setFieldValue("profiles", [{ thread: "", qty: 1 }]);
              }}
            >
              Add Profile
            </Button>
          </div>
        ) : null}
      </FormGroup>
    </div>
  );
};

export const MPI = ({ formikProps: { handleChange, values } }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4">Magnetic Particle Inspection</Typography>
      <Divider className={classes.divider} />
      <FormGroup>
        <FormControlLabel
          className={classes.input}
          control={
            <Switch
              name="mpi"
              onChange={handleChange}
              value={values.mpi}
              checked={values.mpi}
            />
          }
          label="Require Magnetic Particle Inspection"
        />
      </FormGroup>
    </div>
  );
};

export default {
  Item,
  Visual,
  UTT,
  Rebuild,
  Hydro,
  Thread,
  MPI,
};
